import Ratings from "react-ratings-declarative";
import { Link } from "react-router-dom";
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount";
import { useState, useEffect, createContext } from "react";
import {
  ACCESS_TOKEN,
  CUSTOMER_ID,
  SESSION_ID,
  BASE_URL,
  ADD_TO_CART,
  CATITEM,
  DEBUG_LOG_ENABLED,
  getCategoryItemByItemId,
  DEEPLINK_URL,
  BASE_URL_IMG,
  CART_COUNT,
  ERROR_MSG,
  PRIMARY,
} from "../../Constant";
import swal from "sweetalert";
import { Toaster, toast } from "sonner";
import { useSelector, useDispatch } from "react-redux";
import { increment, decrement, handleLoggin } from "../../actions";
import { useNavigate } from "react-router-dom";
// import CartService from "../../services/CartService";
// import FeatureProduct from "../../landing/FeatureProduct";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ROLE, USER_ROLE } from "../../Constant";
import axios from "axios";
import { useParams } from "react-router-dom";
import CustomerReview from "../../landing/CustomerReview";
import moment from "moment";

function ProductDetail(props) {
  const [isHovered, setIsHovered] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const myData = localStorage.getItem("myProduct");
  let itemList = null;

  try {
    if (myData) {
      itemList = JSON.parse(myData);
    } else {
      itemList = {};
    }
  } catch (e) {
    console.error("Failed to parse JSON:", e.message);
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogged = useSelector((state) => state.isLogged);
  const [isLoading, setIsLoading] = useState(false);
  let [itemPrice, setitemPrice] = useState();
  const [item, setItem] = useState(itemList);
  const [priceInDollar, setpriceInDollar] = useState("");
  const [panditcount, setpanditcount] = useState("");
  const Params = useParams();
  const rawId = Params?.id;
  let item_id = null;

  if (rawId) {
    item_id = rawId.split("-")[0];
  }
  const urlToCopy = DEEPLINK_URL + item_id;
  // console.log(item_id, " kkdkk", Params);

  const handleCopy = (pujaName) => {
    navigator.clipboard
      .writeText(urlToCopy + "-" + pujaName)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      })
      .catch((err) => console.error("Failed to copy URL:", err));
  };

  const addToMycart = () => {
    // e.preventDefault();
    const id = localStorage.getItem(CUSTOMER_ID);
    const token = localStorage.getItem(ACCESS_TOKEN);

    // console.log("CartService :" + isLogged + "id:" + id + "  token:" + token);

    if (isLogged && id != null && id != "") {
      addToCart();
    } else {
      navigate("/login");
    }
  };
  const addToCart = async () => {
    if (itemPrice) {
      try {
        const itemData = JSON.stringify({
          pubInfo: {
            sessionId: localStorage.getItem(SESSION_ID),
          },
          request: {
            busiParams: {
              id: 0,
              customerId: localStorage.getItem(CUSTOMER_ID),
              itemId: item?.itemId,
              date: item?.headerColor
                ? moment(item.headerColor)
                    .set({ hour: 9, minute: 0 })
                    .format("YYYY-MM-DDTHH:mm") // Format to match input
                : moment().format("YYYY-MM-DDTHH:mm"),
              price: itemPrice,
              panditCount: JSON.parse(priceInDollar),
            },
            isEncrypt: false,
            transactionId: "897987987989",
          },
        });
        if (DEBUG_LOG_ENABLED) {
          console.log("CartService : " + itemData);
        }

        let response = await axios.post(BASE_URL + ADD_TO_CART, itemData, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        });

        console.log("itemData, ", itemData);
        toast.success(response?.data?.respData?.message);
        //dispatch({type:ADD_CART, payload: 10})
        dispatch(increment(response?.data?.respData?.respMsg?.cartCount));
        localStorage.setItem(
          CART_COUNT,
          response?.data?.respData?.respMsg?.cartCount
        );
      } catch (err) {
        if (err.response.status == 401) {
          localStorage.setItem(CUSTOMER_ID, "");
          navigate("/login");
        } else {
          toast.error(ERROR_MSG);
        }
      }
    } else {
      toast.warning("please select the Pooja");
    }
  };

  //console.log(Params);

  const getCategoryItem = async () => {
    // console.log("fg", item_id);
    const session = localStorage.getItem(SESSION_ID);
    setIsLoading(true);
    try {
      const ItemData = JSON.stringify({
        pubInfo: {
          sessionId: session ?? "74FB1A65BE892907F529DE855156C936",
        },
        request: {
          busiParams: {
            itemId: item_id,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      let response = await axios.post(
        BASE_URL + getCategoryItemByItemId,
        ItemData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      setItem(response.data?.respData?.respMsg?.item);
      // console.log(response.data?.respData?.respMsg?.item, "kks");
    } catch (error) {
      setIsLoading(false);
      // console.log(error, "gck");
      toast.error(ERROR_MSG);
    }
  };

  const editMode = useSelector((state) => state.editMode.editMode);

  const [parsedDescription, setParsedDescription] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (itemList == null) {
      getCategoryItem();
    } else if (item_id != itemList?.itemId) {
      getCategoryItem();
    }
  }, []);

  let [hindi, setHindi] = useState(true);
  const changeHindi = () => {
    setHindi(!hindi);
  };
  const changeEnglish = () => {
    setHindi(!hindi);
  };

  const handleAddressIdchang = (e, item) => {
    e.preventDefault();
    const price = item.price;
    const priceInDollar = item.priceInDoller;
    const panditcount = item.days;
    setpanditcount(panditcount);
    setpriceInDollar(priceInDollar);
    setitemPrice(price);
  };

  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    // console.log("Parsing description...");
    try {
      if (item?.description) {
        const parsed = JSON.parse(item.description);
        setParsedDescription(parsed);
      } else {
        setParsedDescription(null);
      }
    } catch (err) {
      console.error("Failed to parse item.description:", err.message);
      setParsedDescription(null);
    }
  }, [item?.description]);

  useEffect(() => {
    // console.log("skksskk");
    if (parsedDescription && parsedDescription.length > 0) {
      const item = parsedDescription[0];
      // console.log(item, "ddsss");
      const price = item.price;
      const priceInDollar = item.priceInDoller;
      const panditcount = item.days;
      setpanditcount(panditcount);
      setpriceInDollar(priceInDollar);
      setitemPrice(price);
    }
  }, [parsedDescription]);

  return (
    <div
      className=" mt-5 pt-5 py-4 px-xl-5 px-2"
      style={{ minHeight: "600px" }}
    >
      {/* <ScrollToTopOnMount /> */}
      {/* <nav aria-label="breadcrumb" className="bg-custom-light rounded mb-4">
        <ol className="breadcrumb p-3">
          <li className="breadcrumb-item">
            <Link className="text-decoration-none link-secondary">
              {item?.title}
            </Link>
          </li>
        </ol>
      </nav> */}
      <Toaster position="top-right" richColors={true} />
      {isLoading ? (
        <div
          class="spinner-border text-primary mx-auto d-flex "
          role="status"
          style={{
            fontSize: "12px",
            width: "18px",
            height: "18px",
          }}
        >
          <span class="sr-only">Loading...</span>
        </div>
      ) : (
        <>
          <div className="row mb-4">
            <div className="col-lg-5 ">
              <div className="row">
                <div className="col-12 mb-4 ">
                  <img
                    className="border-0  ratio ratio-1x1"
                    alt=""
                    src={BASE_URL_IMG + item?.iconURL}
                    style={{ borderRadius: "18px", objectFit: "cover" }}
                    width={window?.innerWidth > 768 ? "456px" : "287"}
                    height={window?.innerWidth > 768 ? "446px" : "277"}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-7">
              <div className="d-flex flex-column h-100 ">
                <div className=" d-flex align-items-center ">
                  <div className=" fs-4 mb-0 fw-bold mx-1">{item?.title}</div>
                  <div
                    className="ms-auto"
                    style={{ position: "relative", textAlign: "center" }}
                  >
                    {/* Button with hover and click */}
                    <div
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                      onClick={() => handleCopy(item?.title)}
                      className="text-primary px-2 py-1 shadow-sm"
                      style={{
                        cursor: "pointer",
                        // border: "1px solid #ccc",
                        borderRadius: "5px",
                        display: "inline-block",
                      }}
                    >
                      <FontAwesomeIcon
                        icon="fa-sharp fa-light fa-share-nodes text-primary"
                        beat
                      />
                    </div>

                    {/* URL on hover */}
                    {isHovered && (
                      <div
                        className="d-flex"
                        style={{
                          position: "absolute",
                          top: "100%", // Below the button
                          left: "100%", // Centered relative to the button
                          transform: "translateX(-100%)", // Adjust for perfect centering
                          background: "#f9f9f9",
                          padding: "5px 0px",
                          border: "1px solid #ddd",
                          borderRadius: "5px",
                          maxWidth:
                            window?.innerWidth > 768 ? "400px" : "300px",
                          zIndex: 2,
                          boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                          marginTop: "5px", // Add spacing between button and tooltip
                          whiteSpace: "nowrap", // Prevent breaking the URL into multiple lines
                        }}
                      >
                        <span
                          onClick={() => handleCopy(item?.title)}
                          className="px-2 text-start"
                          style={{
                            overflow: "hidden",
                            cursor: "pointer",
                            whiteSpace: "wrap",
                          }}
                        >
                          {" "}
                          https://shreesarvshakti.com/{item?.title}{" "}
                        </span>
                        {/* <span
                          style={{
                            borderTopRightRadius: "5px",
                            borderBottomRightRadius: "5px",
                            padding: "8px",
                            boxShadow: "0 0px 5px rgba(0,0,0,0.2)",
                          }}
                          className="bg-secondary"
                        >
                          copy
                        </span> */}
                        {/* {urlToCopy} */}
                      </div>
                    )}
                    {/* Copy confirmation */}
                    {isCopied && (
                      <div
                        style={{
                          position: "absolute",
                          top: "-50%", // Below the button
                          left: "-150%", // Centered relative to the button
                          transform: "translateX(-50%) translateY(50%)", // Adjust position below hover tooltip
                          background: "#dff0d8",
                          color: "#3c763d",
                          padding: "5px 10px",
                          borderRadius: "5px",
                          fontSize: "12px",
                          zIndex: 2,
                          marginTop: "5px", // Space between tooltip and confirmation
                          // boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                        }}
                      >
                        Copied!
                      </div>
                    )}
                  </div>
                </div>

                <div className="">
                  {Array.isArray(parsedDescription) ? (
                    <div className="row row-cols-2 ">
                      {parsedDescription?.map((card, idx) => (
                        <div
                          key={card.price}
                          className={`flex mt-3 px-3 d-flex border-0 `}
                          style={{
                            height: "auto",
                          }}
                        >
                          <div
                            onClick={(e) => handleAddressIdchang(e, card)}
                            className={`px-4 py-2 rounded shadow-sm border ${
                              itemPrice === card.price ? "border-primary" : ""
                            }`}
                            style={{
                              cursor: "pointer",
                              width: "100%",
                              height: "100%",
                              // borderColor: "rgba(246, 130, 32, 1)",
                              // border: "3px",
                              transition: "background-color 0.3s",
                              backgroundColor:
                                itemPrice === card.price
                                  ? "rgba(254, 243, 233, 1)"
                                  : "transparent", // Active color
                            }}
                            onMouseEnter={
                              (e) =>
                                (e.currentTarget.style.backgroundColor =
                                  "rgba(254, 243, 233, 1)") // Hover effect color
                            }
                            onMouseLeave={
                              (e) =>
                                (e.currentTarget.style.backgroundColor =
                                  itemPrice === card.price
                                    ? "rgba(254, 243, 233, 1)" // Active color remains
                                    : "transparent") // Default color
                            }
                          >
                            <div className="col-12">
                              <p
                                className="fw-bold"
                                style={{
                                  margin: "0",
                                  fontSize: "24px",
                                  fontFamily: "sans-serif",
                                }}
                              >
                                {"₹" + card.price + " / $" + card.priceInDoller}{" "}
                              </p>
                              <p
                                className="card-title"
                                style={{
                                  color: "rgba(0, 0, 0, 0.6)",
                                  fontSize: "16px",
                                  margin: 0,
                                }}
                              >
                                <ul
                                  style={{
                                    listStyleType: "disc",
                                    paddingLeft: "20px",
                                    margin: 0,
                                  }}
                                >
                                  <li>{card.days}</li>
                                  <li>{card.priests}</li>
                                  <li>{card.chants}</li>
                                  {card?.duration && <li>{card.duration}</li>}
                                </ul>
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                {/* <div className="my-5">
                <h5> {parse(`${item?.viewMoreTitle}`)}</h5>
                <h4 className="text-muted  pt-2">
                  {"₹" + item?.price + "/- " + "" + "$" + item?.priceInDollar}
                </h4>
              </div> */}
                <div
                  className="row g-3 mt-3 mb-sm-4  sm-auto  ms-sm-auto"
                  style={{ marginRight: "1px", marginLeft: "1px" }}
                >
                  {editMode ? (
                    <Link
                      className="btn btn-outline-primary  align-content-center  mx-2"
                      to={"/addcatitem"}
                      // replace
                      onClick={() =>
                        localStorage.setItem(CATITEM, JSON.stringify(item))
                      }
                      // type="submit"
                      style={{ width: "150px", height: "auto" }}
                    >
                      <FontAwesomeIcon icon="fa-solid fa-pen-to-square" /> Edit
                    </Link>
                  ) : null}
                  <div className="col me-0">
                    <button
                      className="btn btn-primary py-2 w-100 text-white "
                      onClick={addToMycart}
                      style={{
                        minWidth: window?.innerWidth > 576 ? 200 : 100,
                      }}
                    >
                      Add to cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 mb-4">
              <div className="row row-cols-1 row-cols-md-3 row-cols-lg-4 g-3"></div>
            </div>
          </div>

          <div className="">
            <div className="row g-3">
              <div className="col-lg-12 ">
                <div className=" bg-transparent ">
                  <div
                    className="px-3 d-flex  overflow-auto "
                    style={{ height: 70 }}
                  >
                    <ul className="nav nav-pills my-auto flex-nowrap gap-3">
                      <li className="nav-item">
                        <button
                          onClick={changeEnglish}
                          className={`btn ${
                            hindi
                              ? "btn-primary text-white"
                              : "btn-outline-primary"
                          }`}
                        >
                          English
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          onClick={changeHindi}
                          className={`btn ${
                            !hindi
                              ? "btn-primary text-white"
                              : "btn-outline-primary"
                          }`}
                        >
                          Hindi
                        </button>
                      </li>
                    </ul>
                  </div>

                  {(item?.subTitle || item?.subTitleID) && (
                    <div
                      data-bs-spy="scroll"
                      data-bs-target="#navbar-example2"
                      data-bs-root-margin="0px 0px -40%"
                      data-bs-smooth-scroll="true"
                      className="scrollspy-example bg-light p-3 rounded-2 bg-transparent"
                      bindex="0"
                      style={{}}
                    >
                      {hindi ? (
                        <div
                          className="card-body"
                          style={{
                            flexWrap: "wrap",
                            wordWrap: "break-word", // Ensures long words wrap
                            overflowWrap: "break-word", // Handles modern wrapping
                            whiteSpace: "normal", // Prevents text
                          }}
                        >
                          <small
                            style={{
                              flexWrap: "wrap",
                              wordWrap: "break-word", // Ensures long words wrap
                              overflowWrap: "break-word", // Handles modern wrapping
                              whiteSpace: "normal", // Prevents text
                            }}
                          >
                            {item?.subTitle?.length > 1750 ? (
                              isExpanded ? (
                                parse(item?.subTitle)
                              ) : (
                                <>
                                  {parse(item?.subTitle.substring(0, 1750))}
                                  ...{" "}
                                </>
                              )
                            ) : (
                              parse(item?.subTitle)
                            )}
                            {item?.subTitle?.length > 1750 && (
                              <button
                                className="btn btn-link p-0"
                                onClick={() => setIsExpanded(!isExpanded)}
                              >
                                {isExpanded ? "Show less" : "Show more"}
                              </button>
                            )}
                          </small>
                          <hr />
                        </div>
                      ) : (
                        <p className="lead flex-shrink-0">
                          <small>
                            {item?.subTitleID?.length > 1750 ? (
                              isExpanded ? (
                                parse(item?.subTitleID)
                              ) : (
                                <>
                                  {parse(item?.subTitleID.substring(0, 1750))}
                                  ...{" "}
                                </>
                              )
                            ) : (
                              parse(item?.subTitleID)
                            )}
                            {item?.subTitleID?.length > 1750 && (
                              <button
                                className="btn btn-link p-0"
                                onClick={() => setIsExpanded(!isExpanded)}
                              >
                                {isExpanded ? "Show less" : "Show more"}
                              </button>
                            )}
                          </small>
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <CustomerReview itemid={item?.itemId} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ProductDetail;
