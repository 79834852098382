import React, { useState, useEffect } from "react";
import GetCategoryItemService from "../services/GetCategoryItemService";
import {
  MYPRODUCT,
  SESSION_ID,
  DEBUG_LOG_ENABLED,
  USER_ROLE,
  ROLE,
  BASE_URL_IMG,
  CATITEM,
} from "../Constant";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import gods from "../data/god_pic.json";
import defaultBannerData from "../data/default_banner_data.json";
import { havan, swastik, torans } from "../utils/icons";
import { width } from "@fortawesome/free-brands-svg-icons/fa42Group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";
import moment from "moment";
import { useSelector } from "react-redux";

const mainImageStyles = {
  objectFit: "fill",
  width: "100%",
  maxWidth: "442px",
  minWidth: "100%",
  height: "277px",
  cursor: "pointer",
  ...(window.innerWidth >= 768 &&
    window.innerWidth < 992 && { minWidth: "100%", height: "400px" }),
  ...(window.innerWidth >= 992 && { maxWidth: "442px", height: "446px" }),
};

const overlayImageStyles = {
  marginTop: "-43px",

  width: "85px",
  height: "125px",
  ...(window.innerWidth >= 992 && {
    marginTop: "-90px",
    width: "190px",
    height: "278px",
  }),
};

function Banner(item) {
  const [catItemList, setCatItemList] = useState([]);

  const scrollRef = useRef(null);
  const indicatorRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(moment().format("MMM"));
  const dropdownRef = useRef(null);

  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  const monthOptions = Array.from({ length: 12 }, (_, i) =>
    moment().month(i).format("MMMM")
  );

  const handleMonthSelect = (month) => {
    setSelectedMonth(moment(month, "MMMM").format("MMM"));
    setIsDropdownOpen(false);
    // You can add further logic for handling the date selection here if needed.
  };

  useEffect(() => {
    const sortedItems = item?.product?.items?.sort((a, b) => {
      const dateA = new Date(a?.headerColor).getTime();
      const dateB = new Date(b?.headerColor).getTime();
      return dateA - dateB;
    });

    setCatItemList(sortedItems);
  }, [item]);
  // Close dropdown on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleIndicatorVisibility = (index) => {
    const indicatorContainer = indicatorRef.current;
    const indicator = indicatorContainer?.children[index]; // Target the active dot

    if (indicator && indicatorContainer) {
      const indicatorRect = indicator.getBoundingClientRect();
      const containerRect = indicatorContainer.getBoundingClientRect();

      const offsetLeft = indicatorRect.left - containerRect.left;
      const offsetRight = indicatorRect.right - containerRect.right;

      if (offsetLeft < 0) {
        // Scroll left if the indicator is out of view on the left side
        indicatorContainer.scrollBy({
          left: offsetLeft - 10, // Add some padding
          behavior: "smooth",
        });
      } else if (offsetRight > 0) {
        // Scroll right if the indicator is out of view on the right side
        indicatorContainer.scrollBy({
          left: offsetRight + 10, // Add some padding
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    if (!scrollRef.current || catItemList?.length === 0) return;

    let interval;
    if (!isHovering) {
      interval = setInterval(() => {
        const scrollContainer = scrollRef.current;
        const itemWidth =
          scrollContainer.firstChild?.getBoundingClientRect().width || 0;
        if (currentIndex < catItemList?.length - 1) {
          const newIndex = currentIndex + 1;
          scrollContainer.scrollTo({
            left: newIndex * itemWidth,
            behavior: "smooth",
          });
          setCurrentIndex(newIndex);
          handleIndicatorVisibility(newIndex);
        } else {
          scrollContainer.scrollTo({
            left: 0,
            behavior: "smooth",
          });
          setCurrentIndex(0);
          handleIndicatorVisibility(0);
        }
      }, 3000); // Adjust interval timing as needed
    }

    return () => clearInterval(interval);
  }, [currentIndex, catItemList, isHovering]);

  const handleDotClick = (index) => {
    const scrollContainer = scrollRef.current;
    const card = scrollContainer?.firstChild; // Get the first card for width calculation
    const itemWidth = card ? card.getBoundingClientRect().width : 0; // True width including padding/margin

    if (scrollContainer) {
      scrollContainer.scrollTo({
        left: index * itemWidth,
        behavior: "smooth",
      });
      setCurrentIndex(index); // Update the active index
      handleIndicatorVisibility(index);
    }
  };

  const handleNextClick = () => {
    const scrollContainer = scrollRef.current;
    const card = scrollContainer?.firstChild;
    const itemWidth = card ? card.getBoundingClientRect().width : 0;

    if (currentIndex < catItemList?.length - 1) {
      const newIndex = currentIndex + 1;
      scrollContainer.scrollTo({
        left: newIndex * itemWidth,
        behavior: "smooth",
      });
      setCurrentIndex(newIndex); // Update the active index
      handleIndicatorVisibility(newIndex);
    } else {
      scrollContainer.scrollTo({
        left: 0,
        behavior: "smooth",
      });
      setCurrentIndex(0);
      handleIndicatorVisibility(0);
    }
  };

  const handlePrevClick = () => {
    const scrollContainer = scrollRef.current;
    const card = scrollContainer?.firstChild;
    const itemWidth = card ? card.getBoundingClientRect().width : 0;

    if (currentIndex > 0) {
      const newIndex = currentIndex - 1;
      scrollContainer.scrollTo({
        left: newIndex * itemWidth,
        behavior: "smooth",
      });
      setCurrentIndex(newIndex); // Update the active index
      handleIndicatorVisibility(newIndex);
    } else {
      const newIndex = catItemList?.length - 1;
      scrollContainer.scrollTo({
        left: newIndex * itemWidth,
        behavior: "smooth",
      });
      setCurrentIndex(newIndex);
      handleIndicatorVisibility(newIndex);
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${swastik})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className="mt-5 pt-5 "
    >
      <div
        ref={scrollRef}
        className="d-flex no-scrollbar pe-3"
        style={{
          width: "100%",
          gap: window.innerWidth > 768 ? "10px" : "2px",
          overflowX: "auto",
          whiteSpace: "nowrap",
          scrollSnapType: "x mandatory",
          minHeight: window.innerWidth > 768 ? "400px" : "500px",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {catItemList?.map((it, index) => (
          <div
            key={index}
            className="ps-md-3 ps-3"
            style={{
              // minWidth:window.innerWidth > 768? "90%":"90%",
              minWidth: "90%",
              scrollSnapAlign: "start",
              marginRight: window.innerWidth > 768 ? "16px" : "0",
              transition: "transform 0.5s ease",
              borderRadius: "8px",
            }}
          >
            <BannerCrouselImage item={it} idx={index} />
          </div>
        ))}
      </div>

      {/* Indicators */}
      <div className="mx-3">
        <div
          className="d-flex justify-content-center mt-3 align-items-center  mx-auto "
          style={{ gap: window?.innerWidth > 768 ? "16px" : "1px" }}
        >
          <div
            className="position-relative "
            // ref={dropdownRef}
          >
            <p
              // onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="px-2 rounded-2 text-primary d-flex"
              style={{ cursor: "pointer", margin: 0, alignItems: "center" }}
            >
              <span
                className="text-black fw-semibold pe-1"
                style={{ fontFamily: "sans-serif" }}
              >
                {catItemList?.[currentIndex]?.headerColor
                  ? moment(
                      catItemList[currentIndex].headerColor,
                      "YYYY-MM-DD"
                    ).format("MMM")
                  : "--"}
              </span>
              {/* <FontAwesomeIcon icon="fa-solid fa-angle-down" /> */}
            </p>

            {/* Dropdown Menu */}
            {/* {isDropdownOpen && (
                      <div
                        className="position-absolute bg-white border rounded shadow"
                        style={{
                          top: "100%",
                          left: -2,
                          zIndex: 10,
                          maxHeight: "200px", 
                          overflowY: "scroll",
                          transition: "opacity 0.3s ease, transform 0.3s ease",
                          transform: "translateY(10px)",
                          opacity: isDropdownOpen ? 1 : 0,
                          minWidth: "100px"
                        }}
                      >
                        {monthOptions.map((month, index) => (
                          <p
                            key={index}
                            onClick={() => handleMonthSelect(month)}
                            className="m-0 p-2 text-start"
                            style={{
                              cursor: "pointer",
                              fontFamily: "sans-serif",
                              transition: "background-color 0.3s ease",
                            }}
                            onMouseEnter={(e) => e.target.style.backgroundColor = "#F5F5F5"}
                            onMouseLeave={(e) => e.target.style.backgroundColor = "transparent"}
                          >
                            {month}
                          </p>
                        ))}
                      </div>
                    )} */}
          </div>

          <p
            onClick={handlePrevClick}
            className="p-2 rounded-2 text-primary"
            style={{ cursor: "pointer", margin: 0 }}
            onMouseEnter={(e) =>
              (e.target.style.backgroundColor = "rgba(247, 95, 1, 0.14)")
            }
            onMouseLeave={(e) =>
              (e.target.style.backgroundColor = "transparent")
            }
          >
            <FontAwesomeIcon icon="fa-solid fa-angle-left" />
          </p>
          <div
            ref={indicatorRef}
            className="d-flex  no-scrollbar"
            style={{
              overflowX: "scroll",
              overflowY: "hidden",
              scrollBehavior: "smooth",
              maxWidth: "70%",
              gap: "8px",
            }}
          >
            {catItemList?.map((item, idx) => (
              <p
                onClick={() => handleDotClick(idx)}
                key={idx}
                className={`d-flex text-center justify-content-center align-items-center fw-semibold`}
                style={{
                  minWidth: 40,
                  height: 41,
                  fontSize: "16px",
                  borderRadius: "6px",
                  fontFamily: "sans-serif",
                  color: idx === currentIndex ? "#FF6100" : "#8C8C8C",
                  backgroundColor:
                    idx === currentIndex ? "rgba(247, 95, 1, 0.14)" : "",
                  transition: "background-color 0.3s ease",
                  cursor: "pointer",
                  margin: 0,
                }}
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = "rgba(247, 95, 1, 0.14)")
                }
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor =
                    idx === currentIndex
                      ? "rgba(247, 95, 1, 0.14)"
                      : "transparent")
                }
              >
                {/* {idx+1}  */}
                {item?.headerColor
                  ? moment(item?.headerColor, "YYYY-MM-DD").format("D")
                  : ".."}
              </p>
            ))}
          </div>
          <p
            onClick={handleNextClick}
            className="p-2 rounded-2 text-primary "
            style={{ cursor: "pointer", margin: 0 }}
            onMouseEnter={(e) =>
              (e.target.style.backgroundColor = "rgba(247, 95, 1, 0.14)")
            }
            onMouseLeave={(e) =>
              (e.target.style.backgroundColor = "transparent")
            }
          >
            <FontAwesomeIcon icon="fa-solid fa-angle-right" />
          </p>
        </div>
      </div>
      {/* Choose By God Section */}
      <div
        className="text-center"
        style={{ marginTop: window?.innerWidth > 768 ? 100 : 40 }}
      >
        <p style={{ margin: 0 }} className="px-4 fs-4 fw-bold">
          Choose Puja By God
        </p>
        <div className="d-flex overflow-auto py-4 px-3 gap-4 justify-content-between no-scrollbar">
          {gods?.godpic.map((item, index) => (
            <div key={index} className="flex-shrink-0">
              <ChooseByGod item={item} idx={index} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Banner;

function BannerCrouselImage({ item, idx }) {
  const navigate = useNavigate();

  const [itemWidth, setItemWidth] = useState(0);
  const itemRef = useRef(null);

  const editMode = useSelector((state) => state.editMode.editMode);

  // useEffect(() => {

  //   const updateItemWidth = () => {
  //     if (itemRef.current) {
  //       setItemWidth(itemRef.current.clientWidth);
  //     }
  //   };

  //   // Initial width calculation
  //   updateItemWidth();

  //   // Add event listener to resize the window
  //   window.addEventListener("resize", updateItemWidth);

  //   // Clean up the event listener when component unmounts
  //   return () => {
  //     window.removeEventListener("resize", updateItemWidth);
  //   };
  // }, []);

  return (
    <>
      {editMode && (
        <div className="d-sm-flex mb-1">
          <Link
            className="btn btn-outline-primary  mt-1"
            style={{ width: "auto", height: "36px" }}
            to={"/addcatitem"}
            // replace
            onClick={() => localStorage.setItem(CATITEM, JSON.stringify(item))}
          >
            <FontAwesomeIcon icon="fa-solid  fa-pen-to-square" />
            Edit
          </Link>
        </div>
      )}
      <div
        key={idx}
        className="  col-md-12"
        style={{
          borderRadius: window.innerWidth > 768 ? "16px" : "8px",
          width: "100%",
          cursor: "pointer",
          backgroundColor: item?.bgcolor
            ? `${item?.bgcolor}`
            : "rgba(75, 36, 12, 1)",
        }}
      >
        <div
          className="d-md-flex  col-md-12 "
          onClick={() => {
            localStorage.setItem(MYPRODUCT, JSON.stringify(item));
            navigate("productdetail/" + item?.itemId);
          }}
        >
          <div
            className="col-md-5"
            style={{
              borderBottomLeftRadius: "16px",
              borderTopLeftRadius: "16px",
            }}
          >
            <img
              src={`${BASE_URL_IMG + item?.iconURL}`}
              // src="/god/NewHanuman.png"
              alt="..."
              style={{
                ...mainImageStyles,
                ...(window.innerWidth > 768
                  ? {
                      borderTopLeftRadius: "16px",
                      borderBottomLeftRadius: "16px",
                    }
                  : {
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px",
                    }),
                objectFit: "cover",
              }}
              className="img-fluid  position-relative"
            />
          </div>
          <div
            className="col-md-7  position-relative rounded-end  text-md-start  "
            style={{ height: window.innerWidth > 768 ? "" : "301px" }}
          >
            <img
              src={havan}
              alt="..."
              className="position-absolute "
              style={{
                ...(window.innerWidth > 768
                  ? {
                      borderTopRightRadius: "16px",
                      borderBottomRightRadius: "16px",
                    }
                  : {
                      borderBottomLeftRadius: "8px",
                      borderBottomRightRadius: "8px",
                    }),
                objectFit: "cover",
                width: "100%",
                height: window.innerWidth > 768 ? "100%" : "301px",
              }}
            />
            <div
              className="position-absolute top-0 start-0 px-lg-5 px-md-3 px-2 text-white "
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <h1
                className="fw-semibold text-wrap mt-3 py-1"
                style={{
                  fontSize: window.innerWidth > 768 ? "40px" : "22px",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  WebkitLineClamp: 1, // Truncates text to 1 line if needed
                  whiteSpace: "normal",
                }}
              >
                {item?.title}
              </h1>

              <p
                className=" text-wrap"
                style={{
                  fontSize: window.innerWidth > 768 ? "24px" : "16px",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  color: "rgba(255, 255, 255, 0.9)",
                  WebkitLineClamp: 4, // Adjusts lines based on screen size
                  whiteSpace: "normal",
                  marginTop: window.innerWidth > 768 ? "8px" : "0px",
                }}
              >
                {/* {item?.subTitleID.replace(/(<([^>]+)>)/gi, "")} */}
                {(() => {
                  try {
                    const parsedCallingLink = item?.callingLink
                      ? JSON.parse(item.callingLink)
                      : null;
                    if (Array.isArray(parsedCallingLink)) {
                      return (
                        <>
                          <span>Benefits</span>
                          {parsedCallingLink.map((linkItem, index) => (
                            <li key={index}>{linkItem?.benefit}</li>
                          ))}
                        </>
                      );
                    }
                  } catch (error) {
                    console.error("Error parsing callingLink:", error);
                  }

                  return item?.subTitleID?.replace(/(<([^>]+)>)/gi, "");
                })()}
              </p>
              <div
                className="d-flex justify-content-center  px-auto mx-auto mt-auto mb-0   text-center gap-md-4 gap-2"
                style={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  overflowY: "hidden",
                  overflowX: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "normal",
                  width: "100%",
                }}
              >
                {item?.headerColor && (
                  <>
                    <p
                      className="fw-semibold"
                      style={{
                        fontSize: window.innerWidth > 768 ? "24px" : "16px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Date
                      <br />
                      <span
                        className="fw-medium"
                        style={{
                          fontSize: window.innerWidth > 768 ? "20px" : "16px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {moment(item?.headerColor, "YYYY-MM-DD").format(
                          "D MMM"
                        )}
                      </span>
                    </p>
                    <div
                      className=""
                      style={{
                        borderLeft: "1.6px solid white",
                        height: "90%",
                        alignSelf: "center",
                      }}
                    />
                  </>
                )}
                {item?.callingTag && (
                  <>
                    <p
                      className="fw-semibold d-none d-sm-block "
                      style={{
                        fontSize: window.innerWidth > 768 ? "24px" : "16px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Time
                      <br />
                      <span
                        className="fw-medium"
                        style={{
                          fontSize: window.innerWidth > 768 ? "20px" : "16px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item?.callingTag}
                      </span>
                    </p>
                    <div
                      className="d-none d-sm-block"
                      style={{
                        borderLeft: "1.6px solid white",
                        height: "90%",
                        alignSelf: "center",
                      }}
                    />
                  </>
                )}
                {item?.panditCount && (
                  <>
                    <p
                      className="fw-semibold"
                      style={{
                        fontSize: window.innerWidth > 768 ? "24px" : "16px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "normal",
                      }}
                    >
                      Priest
                      <br />
                      <span
                        className="fw-medium"
                        style={{
                          fontSize: window.innerWidth > 768 ? "20px" : "16px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item?.panditCount}
                      </span>
                    </p>
                    <div
                      className=""
                      style={{
                        borderLeft: "1.6px solid white",
                        height: "90%",
                        alignSelf: "center",
                      }}
                    />
                  </>
                )}
                {(item?.panditPrice || item?.panditPriceInDollar) && (
                  <p
                    className="fw-semibold"
                    style={{
                      fontSize: window.innerWidth > 768 ? "24px" : "16px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Price
                    <br />
                    <span
                      className="fw-medium"
                      style={{
                        fontSize: window.innerWidth > 768 ? "20px" : "16px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item?.panditPrice && "₹ " + item?.panditPrice + " |"}{" "}
                      {item?.panditPriceInDollar &&
                        " $ " + item?.panditPriceInDollar}
                    </span>
                  </p>
                )}
              </div>
              <div
                className="bg-primary rounded text-center px-auto text-white d-flex align-items-center justify-content-center mt-auto mb-md-4  mb-3 fw-semibold"
                onMouseEnter={(e) => (e.target.style.opacity = 0.9)}
                onMouseLeave={(e) => (e.target.style.opacity = 1)}
                style={{
                  // width: "110px",
                  fontSize: window.innerWidth > 768 ? "20px" : "18px",
                  fontFamily: "monospace",
                  height: window.innerWidth > 768 ? "44px" : "35px",
                  width: window.innerWidth > 768 ? "242px" : "132px",
                  cursor: "pointer",
                  marginLeft: "auto",
                }}
                onClick={() => {
                  localStorage.setItem(MYPRODUCT, JSON.stringify(item));
                  navigate("productdetail/" + item?.itemId);
                }}
              >
                Book Puja
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const ChooseSlieds = [
  {
    itemId: "335",
  },
  {
    itemId: "636",
  },
  {
    itemId: "342",
  },
  {
    itemId: "634",
  },
  {
    itemId: "395",
  },
  {
    itemId: "656",
  },
];

const ChooseByGod = ({ item, idx }) => {
  const navigate = useNavigate();

  return (
    <div
      className="text-center"
      style={{ cursor: "pointer", width: "120px" }}
      onClick={() => {
        // localStorage.setItem(MYPRODUCT, JSON.stringify(fixeditem));
        localStorage.removeItem(MYPRODUCT);
        navigate("productdetail/" + ChooseSlieds[idx]?.itemId ?? "632");
      }}
    >
      {" "}
      {/* Set width for uniform sizing */}
      <img
        src={item.image}
        alt={"..."}
        className="img-fluid rounded-circle"
        style={{ objectFit: "cover", width: "100px", height: "100px" }} // Adjust as needed
      />
    </div>
  );
};
