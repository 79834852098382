import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import TextCardItemView from "./TextCardItemView";
import { Link } from "react-router-dom";

function Headline({ item, navigate, showEdit, nama }) {
  const data = () => {
    //  navigate("/itemsviewmore");
    localStorage.setItem("myData", JSON.stringify(item));
    localStorage.setItem("myDataview", nama);
  };
  return (
    <div
      style={{}}
      className="d-flex justify-content-between align-items-center mt-md-5 mt-3 py-3 "
    >
      {/* Title Block */}
      <div
        className="col ps-4 d-flex"
        style={{
          backgroundColor: "rgba(247, 95, 1, 0.1)",
          // borderTopRightRadius: "8px",
          // borderBottomRightRadius: "8px",
          // fontFamily:'sans-serif'
        }}
      >
        <p
          className="fs-4 fw-bold"
          style={{
            margin: 0,
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
            overflow: "hidden",
            // fontFamily: "sans-serif",
          }}
        >
          {item?.title}
        </p>
        <div className="ms-auto d-flex align-items-center">
          <Link
            to={"/itemsviewmore"}
            className="btn border-0 btn-light bg-transparent text-primary text-truncate me-2"
            style={{
              fontWeight: 700,
              margin: 0,
              // backgroundColor: "rgba(247, 95, 1, 0.1)",
            }}
            onClick={() => data()}
          >
            See all
            <FontAwesomeIcon
              icon="fa-solid fa-greater-than"
              className="ps-2"
              fade
            />
          </Link>
          {showEdit ? (
            <Link
              to={"/addcat"}
              className="btn btn-outline-primary"
              onClick={() =>
                navigate(localStorage.setItem("catData", JSON.stringify(item)))
              }
              type="submit"
            >
              <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
              Edit
            </Link>
          ) : null}
        </div>
      </div>

      {/* Links Block */}
    </div>
  );
}

export default Headline;
