import counterReducer from "./counter";
import loggedReducer from "./isLogged";
import { combineReducers } from "redux";
import LanguageSelector from "./LanguageSelector";
import editReducer from "../redux/toggleReducer";

const allReducers = combineReducers({
  //we access this by any key we want
  counter: counterReducer,
  isLogged: loggedReducer,
  Language: LanguageSelector,
  editMode: editReducer,
});
export default allReducers;
