import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useParams } from "react-router-dom";
// import TextCardItemView from "../landing/TextCardItemView";
import {
  SESSION_ID,
  BASE_URL,
  PRODUCT_LIST,
  ACCESS_TOKEN,
  SEARCH_API,
  DEBUG_LOG_ENABLED,
  USER_ROLE,
  ROLE,
  CARD_VIEW,
  TOP_PRODUCT,
  you_tube_view,
} from "../Constant";
import swal from "sweetalert";
import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import ProductGridCard from "../landing/ProductGridCard";
import ProductItemCart from "../landing/ProductItemCart";
import YouTubecard from "../landing/YouTubecard";
import FeatureProduct from "../landing/FeatureProduct";
// import ErrorImages from "../Images/error.png"
import ErrorImages from "../images/error.png";

function ItemsViewMore() {
  const myDataview = localStorage.getItem("myDataview");
  let [showEdit, setShowEdit] = useState(false);
  const [viewType, setViewType] = useState({ grid: true });
  const [view, setView] = useState("");
  let [ItemShow, setItemShow] = useState(true);

  function changeViewType() {
    setViewType({
      grid: !viewType.grid,
    });
  }
  const navigate = useNavigate();
  const [defaultData, setDefaultData] = useState([]);
  const [catItemList, setCatItemList] = useState([]);
  const [value, setvalue] = useState("");
  useEffect(() => {
    // const myDataview = localStorage.getItem("myDataview");
    // setView(myDataview);
    const userRole = localStorage.getItem(USER_ROLE);
    if (userRole?.includes(ROLE)) {
      setShowEdit(true);
    }
    const myData = localStorage.getItem("myData");
    if (myData) {
      const itemList = JSON.parse(myData);
      setCatItemList(itemList.items);
      setDefaultData(itemList.items);
    }
  }, []);

  const handleChange = (e) => {
    var value = e.target?.value;
    setvalue(value);
    if (value?.length !== 0) {
      debounceSearch(value);
    } else {
      setItemShow(true);
      setCatItemList(defaultData);
    }
  };

  const Search = async (value) => {
    try {
      const searchApi = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            title: value,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      // const response = await SearchApi.create(searchApi);

      let response = await axios.post(BASE_URL + SEARCH_API, searchApi, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });
      setCatItemList(response?.data?.respData?.respMsg?.catItemList);

      if (response.data.respData.respMsg.catItemList.length === 0) {
        setItemShow(false);
      } else {
        setItemShow(true);
      }
    } catch (err) {
      swal("data : " + err);
      if (DEBUG_LOG_ENABLED) {
        console.log(err);
      }
    }
  };
  const debounceSearch = (() => {
    let timer;
    return (value) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        Search(value);
      }, 500);
    };
  })();

  return (
    <div className="mt-5">
      <div className="px-md-3 px-2 mt-5 pt-4  ">
        <div className="mt-lg-3">
          <div className="col ">
            <div
              className=" d-flex  mx-auto mt-4  mb-sm-4 mb-3"
              style={{ maxWidth: "350px" }}
            >
              <div className="input-group">
                <input
                  className="form-control"
                  value={value}
                  onChange={(e) => handleChange(e)}
                  type="search"
                  placeholder="Search..."
                  aria-label="Search"
                  style={{ outline: "none", boxShadow: "none" }}
                  // size="32"
                />
                <button type="button" className="btn btn-primary ">
                  <FontAwesomeIcon icon={["fas", "search"]} />
                </button>
              </div>
              {/* <div>{view}</div> */}
            </div>

            {ItemShow ? (
              <div
                className={
                  // "row row-cols-2 row-cols-md-6 row-cols-lg-6 g-3  d-flex justify-content-start px-4  " +
                  // (viewType.grid ? "row-cols-xl-5" : "row-cols-xl-2")
                  "d-flex justify-content-center pb-4 align-items-center no-scrollbar "
                }
                style={{
                  flexWrap: "wrap",
                  gap: window?.innerWidth > 768 ? "30px" : "10px",
                  maxHeight: "500px",

                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                {catItemList.map((item, index) => {
                  // if (viewType.grid) {
                  //   return <FeatureProduct key={index} product={item} />;
                  // }
                  return <FeatureProduct key={index} product={item} />;
                })}
              </div>
            ) : (
              <div class="d-flex justify-content-center">
                <div className=" text-center">
                  <img src={ErrorImages} style={{ width: "18rem" }} />
                  <h5 className="card-title">No items found</h5>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemsViewMore;
