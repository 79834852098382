import { Link } from "react-router-dom";
import parse from "html-react-parser";
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FeatureProduct from "./FeatureProduct";
import { BASE_URL_IMG, CATDATA, MYDATA } from "../Constant";
import Headline from "./ Headline";
import flower from "../images/art/Flower.png";
const ProductGridCard = ({ item, navigate, showEdit, nama }) => {
  return (
    <div>
      <div class=" mb-3 fw-semibold">
        <Headline
          item={item}
          navigate={navigate}
          showEdit={showEdit}
          nama={nama}
        />
      </div>
      <div
        className="d-flex flex-row flex-nowrap overflow-auto no-scrollbar px-3"
        style={{ gap: "30px" }}
      >
        {item.items.map((it) => (
          <div>
            <FeatureProduct key={it.itemId} product={it} />
          </div>
        ))}
      </div>
    </div>
  );
};
export default ProductGridCard;
