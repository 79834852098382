import React from "react";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import swal from "sweetalert";

import {
  SESSION_ID,
  CUSTOMER_ID,
  BASE_URL,
  GetOrderInfosByDetails,
  ACCESS_TOKEN,
  USER_ROLE,
  ROLE,
  DEBUG_LOG_ENABLED,
  getOrderInfoByDate,
  ADDRESSLIST,
  getCustomerAddressByUserId,
  ERROR_MSG,
  PRIMARY_LIGHT,
} from "../Constant";
import { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { increment } from "../actions";
import GetOrderService from "./GetOrderService";
import RemoveOrderHistoryService from "./RemoveOrderHistoryService";
import AccountMenu from "../profile/account-menu";
import OrderHistoryItem from "../order_history/OrderHistoryItem";
import axios from "axios";
import moment from "moment";
import { toast, Toaster } from "sonner";
import { orderBg, swastik } from "../utils/icons";

function OrderHistory() {
  const navigate = useNavigate();
  const [orderItemList, setOrderItemList] = useState([]);
  const [addressList, setAddressList] = useState([]);
  let [showEdit, setShowEdit] = useState(false);
  const [submitLoaders, setSubmitLoaders] = useState(false);
  let [Loader, setLoader] = useState(false);
  const [dates, setDates] = useState({
    startdate:
      sessionStorage.getItem("startdate") || moment().format("YYYY-MM-DD"),
    endtdate: sessionStorage.getItem("endtdate") || "",
  });
  const userRole = localStorage.getItem(USER_ROLE);

  const filterByAddressId = (addressItemList, addressId) => {
    const addressItem = addressItemList.filter((item) => item.id === addressId);
    return addressItemList;
  };
  const getOrdertLists = async () => {
    // setLoader(false)
    try {
      var userType = 0;
      var custmId = localStorage.getItem(CUSTOMER_ID);
      const userRole = localStorage.getItem(USER_ROLE);

      if (userRole?.includes(ROLE)) {
        custmId = "";
        userType = 1;
      } else {
        custmId = localStorage.getItem(CUSTOMER_ID);
        userType = 0;
      }

      const orderListData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            orderId: "",
            customerId: custmId,
            user: userType,
            date: "",
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      // const response = await GetOrderService.create(orderListData);
      let response = await axios.post(
        BASE_URL + GetOrderInfosByDetails,
        orderListData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        }
      );

      // console.log("data : " + response.data.respData.respMsg.morderInfoList);
      setOrderItemList(response.data.respData.respMsg.morderInfoList);
      setLoader(false);
    } catch (err) {
      if (err.response.status == 401) {
        navigate("/login");
      } else {
        toast.error(ERROR_MSG);
        setLoader(false);
      }
    }
  };

  const getOrderDate = async (startdate, enddate) => {
    try {
      const getOrderByDate = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            startDate: startdate ?? moment().format("YYYY-MM-DD"),
            endDate: enddate ?? moment().format("YYYY-MM-DD"),
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      let response = await axios.post(
        BASE_URL + getOrderInfoByDate,
        getOrderByDate,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        }
      );
      // console.log("ss", response.data.respData.respMsg.morderInfoList);
      setOrderItemList(response.data.respData.respMsg.morderInfoList);
      setLoader(false);
      setSubmitLoaders(false);
    } catch (error) {
      toast.error(ERROR_MSG);
      setLoader(false);
      setSubmitLoaders(false);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const updatedDates = { ...dates, [e.target.name]: value };
    setDates(updatedDates);
    sessionStorage.setItem(e.target.name, value); // Save the updated value in sessionStorage
  };

  const onSubmitDate = () => {
    if (dates.endtdate == "" && dates.startdate == "") {
      toast.info(" Please enter date ");
    } else {
      setSubmitLoaders(true);
      getOrderDate(dates.startdate, dates.endtdate);
    }
  };

  const getHistory = () => {
    if (userRole?.includes(ROLE)) {
      setShowEdit(true);
      getOrderDate(dates.startdate, dates.endtdate);
    } else {
      getOrdertLists();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoader(true);
    getHistory();
  }, []);

  return (
    <div
      className="mt-5 pt-4 px-xl-5 px-md-4 px-3"
      style={{
        paddingBottom: 200,
        backgroundImage: `url(${orderBg})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: window?.innerWidth > 768 ? "700px" : "500px",
      }}
    >
      <Toaster position="top-right" richColors={true} />
      <div className="py-4">
        {showEdit ? (
          <div className="row g-sm-4 g-3 mb-4 align-items-center">
            <div
              className="col-md "
              style={{ maxWidth: window?.innerWidth > 768 ? "180px" : "160px" }}
            >
              <div className="form-floating">
                <input
                  name="startdate"
                  type="date"
                  className="form-control border-0 shadow-sm rounded-3"
                  onChange={(e) => handleChange(e)}
                  value={dates.startdate}
                  required
                  aria-required="true"
                />
                <label htmlFor="floatingInputGrid">Start Date</label>
              </div>
            </div>
            <div className="col-md" style={{ maxWidth: "180px" }}>
              <div className="form-floating">
                <input
                  name="endtdate"
                  type="date"
                  className="form-control border-0 shadow-sm rounded-3"
                  onChange={(e) => handleChange(e)}
                  // value={Enddate}
                  value={dates.endtdate}
                  required
                  aria-required="true"
                />
                <label htmlFor="floatingInputGrid">End Date</label>
              </div>
            </div>
            <div className="col-md ">
              <div className="form-floating">
                <button
                  onClick={onSubmitDate}
                  type="submit"
                  className="btn btn-primary px-4 py-1 text-white border-0 shadow-sm rounded-3"
                >
                  Submit
                  {submitLoaders && (
                    <div
                      className="spinner-border text-light ms-2 "
                      style={{ height: 14, width: 14, fontSize: "10px" }}
                      role="status"
                    ></div>
                  )}
                </button>
              </div>
            </div>
          </div>
        ) : null}

        {orderItemList?.length == 0 && Loader ? (
          <div className="placeholder-glow mt-3 ">
            <div
              className="ratio "
              style={{ "--bs-aspect-ratio": "90%", maxHeight: "230px" }}
            >
              <img
                className="placeholder disabled border-0 w-100 h-100 rounded-2"
                alt=""
                style={{ backgroundColor: PRIMARY_LIGHT }}
              />
            </div>
            <div
              className="ratio mt-4"
              style={{ "--bs-aspect-ratio": "90%", maxHeight: "230px" }}
            >
              <img
                className="placeholder disabled border-0 w-100 h-100 rounded-2"
                alt=""
                style={{ backgroundColor: PRIMARY_LIGHT }}
              />
            </div>
          </div>
        ) : orderItemList?.length === 0 ? (
          <div className="container py-4">
            {showEdit
              ? "There is no history on selected date"
              : "You have no order currently, Please add the item to your cart."}
          </div>
        ) : (
          <div>
            {orderItemList?.map((it) => (
              <OrderHistoryItem
                key={it.orderNum}
                id={it.orderNum}
                product={it}
                oldaddressData={filterByAddressId(addressList, it.addressId)}
                addressData={it?.address}
                showEdit={showEdit}
                getHistory={getHistory}
              />
            ))}
          </div>
        )}
        {/* </div>
        </div> */}
      </div>
    </div>
  );
}

export default OrderHistory;
