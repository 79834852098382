import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import FeatureProduct from "../landing/FeatureProduct";

import {
  SESSION_ID,
  BASE_URL,
  PRODUCT_LIST,
  ACCESS_TOKEN,
  SEARCH_API,
  ABOUT_US_VIEW,
  DEBUG_LOG_ENABLED,
} from "../Constant";
import axios from "axios";
import { useState, useEffect } from "react";
import GetCategoryItemService from "../services/GetCategoryItemService";
import { useNavigate } from "react-router-dom";
import SearchApi from "./SearchApi";

function ProductList() {
  const [viewType, setViewType] = useState({ grid: true });

  function changeViewType() {
    setViewType({
      grid: !viewType.grid,
    });
  }
  const navigate = useNavigate();

  //const responseJson = navigate.getParam("myJSON");
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [catItemList, setCatItemList] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    setSearchValue("");
    setCatItemList([]);
    const value = "Black";
    Search(value);
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setSearchValue(value); // Update controlled input

    if (value?.length === 0) {
      setCatItemList(data); // Reset to initial data
    } else {
      debounceSearch(value); // Perform debounced search
    }
  };

  const Search = async (value) => {
    try {
      const searchApi = JSON?.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            title: value,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      // const response = await SearchApi.create(searchApi);

      let response = await axios.post(BASE_URL + SEARCH_API, searchApi, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          // Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });

      // console.log("fdddff", response);
      const filteredData = response.data.respData.respMsg.catItemList.filter(
        (item) => item.viewType !== ABOUT_US_VIEW
      );

      setCatItemList(filteredData);
      setData(filteredData);

      setIsLoading(false);
    } catch (err) {
      // alert("data : " + err);
      console.log("fff", err);
      setIsLoading(false);
      if (DEBUG_LOG_ENABLED) {
        console.log(err);
      }
    }
  };

  const debounceSearch = (() => {
    let timer;
    return (value) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        Search(value);
      }, 500);
    };
  })();

  return (
    <div className="px-4  mt-5  pt-4 ">
      {/* <ScrollToTopOnMount /> */}

      {/* <div className="container">
        <div className="col-lg-9"> */}
      {/* <div className="d-flex flex-column h-100"> */}

      <div
        className=" d-flex  mx-auto mt-4  mb-sm-4 mb-3"
        style={{ maxWidth: "450px" }}
      >
        <div className="input-group">
          <input
            className="form-control"
            value={searchValue}
            onChange={(e) => handleChange(e)}
            type="search"
            placeholder="Search..."
            aria-label="Search"
            style={{ outline: "none", boxShadow: "none" }}
          />
          <button type="button" className="btn btn-primary ">
            <FontAwesomeIcon icon={["fas", "search"]} />
          </button>
        </div>
      </div>

      <div
        className={
          // "row row-cols-2 row-cols-md-6 row-cols-lg-6 g-3  d-flex justify-content-start px-4  " +
          // (viewType.grid ? "row-cols-xl-5" : "row-cols-xl-2")
          "d-flex justify-content-center align-items-center pb-4 no-scrollbar"
        }
        style={{
          minHeight: "500px",
          flexWrap: "wrap",
          gap: "30px",
          maxHeight: "500px",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        {isLoading ? (
          <div
            className="d-flex justify-content-center mt-5 w-100"
            style={{ minHeight: "200px" }}
          >
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        ) : catItemList?.length > 0 ? (
          catItemList?.map((item, index) => (
            <FeatureProduct key={index} product={item} />
          ))
        ) : (
          <p className="text-center w-100 pt-4" style={{ marginTop: "20px" }}>
            No Search Found
          </p>
        )}
      </div>
      {/* </div>
        </div> */}
      {/* </div> */}
    </div>
  );
}

export default ProductList;
