import { useNavigate } from "react-router-dom";
import LoginService from "../login/LoginService";
import { useState, useContext, useEffect } from "react";
import {
  ACCESS_TOKEN,
  BASE_URL,
  SESSION_ID,
  USER_INFO,
  USER_LOGIN,
  CUSTOMER_ID,
  GET_LOGOUT,
  DEBUG_LOG_ENABLED,
  USER_ROLE,
  CART_COUNT,
} from "../Constant";
import { useDispatch } from "react-redux";
import { handleLoggin, increment } from "../actions";
import axios from "axios";
import swal from "sweetalert";

export default function (props) {
  //const {state, dispatch} = useContext(UserContext)
  const dispatch = useDispatch();

  let [authMode, setAuthMode] = useState("signin");

  const changeAuthMode = () => {
    setAuthMode(authMode === "signin" ? "signup" : "signin");
  };
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [customer, setCustomer] = useState({
    username: "",
    password: "",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setCustomer({ ...customer, [e.target.name]: value });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const logoutCustomer = (e) => {
    // e.preventDefault();
    //dispatch({type:USER_LOGIN, payload: false})
    // loginData();
    const sessionId = localStorage.getItem(SESSION_ID); // Save the session ID value
    localStorage.clear(); // Clear all local storage items
    if (sessionId) {
      localStorage.setItem(SESSION_ID, sessionId); // Restore the session ID
    }

    // localStorage.setItem(CUSTOMER_ID, " ");
    // localStorage.setItem(ACCESS_TOKEN, " ");
    // //localStorage.setItem(SESSION_ID,"");
    // localStorage.setItem(CART_COUNT, " ");
    // localStorage.setItem(USER_LOGIN, " ");
    // localStorage.setItem(USER_ROLE, " ");
    // localStorage.setItem(USER_INFO, " ");
    // localStorage.setItem("cat_ascending_data", " ");
    // localStorage.setItem("categorydata", "");
    dispatch(handleLoggin(false));
    dispatch(increment(0));
    // localStorage.clear();
    navigate("/login");
  };
  const backToHome = (e) => {
    navigate("/");
  };

  const sessionId = localStorage.getItem(SESSION_ID);

  const loginData = async () => {
    try {
      const loginData = JSON.stringify({
        pubInfo: {
          sessionId: sessionId,
        },
        request: {
          busiParams: {
            username: customer.username,
            password: customer.password,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      if (DEBUG_LOG_ENABLED) {
        console.log("LoginService : " + loginData);
      }

      // const response = await LoginService.create(loginData);
      let response = await axios.post(BASE_URL + GET_LOGOUT, loginData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });

      // swal("data : "+response.data.respData.respMsg.sessionId);
      // dispatch(setSession(response.data.respData.respMsg));
      setData(response.data.respData.respMsg);

      //global.loginInfo=response.data.respData.respMsg

      localStorage.setItem(
        USER_INFO,
        JSON.stringify(response.data.respData.respMsg)
      );

      localStorage.setItem(
        ACCESS_TOKEN,
        response.data.respData.respMsg.accessToken
      );

      localStorage.setItem(
        CUSTOMER_ID,
        response.data.respData.respMsg.customerId
      );
      // swal("data11: "+data.sessionId);
      //dispatch({type:"USER", payload: true})
      if (DEBUG_LOG_ENABLED) {
        console.log(data);
      }
      navigate("/");
    } catch (err) {
      swal("data : " + err);
      if (DEBUG_LOG_ENABLED) {
        console.log(err);
      }
    }
  };

  return (
    <div className="align-items-center   py-5  mt-5">
      <div className="d-flex flex-column  align-items-center">
        <div className="card p-5">
          {/* <h2 className="Auth-form-title">Logout</h2> */}
          <div>
            <div className="text-center pt -5">Do you want to logout ? </div>
            <div class="d-flex justify-content-center pt-5">
              <button
                type="button"
                class="btn btn-primary btn-lg me-2 text-white"
                onClick={logoutCustomer}
              >
                Yes
              </button>
              <button
                type="button"
                class="btn btn-primary btn-lg text-white"
                onClick={backToHome}
              >
                No
              </button>
            </div>
            {/*               
              <button
               onClick = {navigate("/")} 
               type="submit" className="btn btn-info">
                No
              </button> 
              <button
               onClick = {logoutCustomer} 
               type="submit" className="btn btn-info">
                Yes
              </button>   
              */}
          </div>
        </div>
      </div>
    </div>
  );
}
