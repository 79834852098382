import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SignUpService from "./SignUpService";
import PropTypes from "prop-types";
import { DEBUG_LOG_ENABLED, BACKGROUNDIMAGE, ERROR_MSG } from "../Constant";
import LoginScrollpages from "../login/LoginScrollpages";
import swal from "sweetalert";
import { Toaster, toast } from "sonner";

export default function (props) {
  let [authMode, setAuthMode] = useState("signin");

  const changeAuthMode = () => {
    setAuthMode(authMode === "signin" ? "signup" : "signin");
  };
  const navigate = useNavigate();

  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [customer, setCustomer] = useState({
    customerId: "",
    name: "",
    emailId: "",
    mobileNo: "",
    password: "",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setCustomer({ ...customer, [e.target.name]: value });
  };

  const saveCustomer = (e) => {
    e.preventDefault();
    if (
      customer.name === "" ||
      customer.password === "" ||
      customer.emailId === "" ||
      customer.mobileNo === ""
    ) {
      toast.warning(" Please enter all fields ");
    } else if (confirmPassword !== customer.password) {
      toast.warning(" Password not matched ");
    } else {
      setIsLoading(true);
      const customerData = JSON.stringify({
        pubInfo: {
          sessionId: "3D3CFD149568C3AD31F4D3A9A9010C84",
        },
        request: {
          busiParams: {
            name: customer.name,
            emailId: customer.emailId,
            mobileNo: customer.mobileNo,
            password: customer.password,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      SignUpService.saveCustomer(customerData)
        .then((response) => {
          setIsLoading(false);
          // console.log("dk>>", response.data.respData.message);
          toast.success("Registration Completed");
          navigate("/login");
          if (DEBUG_LOG_ENABLED) {
            console.log(response.data.respData.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(ERROR_MSG);
          // if (DEBUG_LOG_ENABLED) {
          // console.log(error, "ddd");
          // }
        });
    }
  };
  //   const vv=  <section className=" py-5 px-2">
  //       <div className="container py-5 ">
  //         <div className="card rounded-3 text-black">
  //           <div className="row  ">
  //             <LoginScrollpages />
  //             <div className="col-md-6 col-lg-6 col-xl-4 offset-xl-1">

  //               </div>
  //             </div>
  //             </div>
  //             </div>
  // </section>
  return (
    <div
      className="bg-image"
      style={{
        backgroundImage: BACKGROUNDIMAGE,
        height: "100hv",
      }}
    >
      <Toaster position="top-right" richColors={true} />
      <div className="container py-5 mt-3">
        <div className="card rounded-3 text-black">
          <div className="row  ">
            <LoginScrollpages />
            <div className="col-md-5 col-lg-5 col-xl-4 offset-xl-1">
              <div className="align-center p-3  ">
                <div className="">
                  <div className=" ">
                    <h3 className="text-center mt-4">Sign Up</h3>
                    <div className="text-center">
                      Already registered?
                      <a
                        href="#/login"
                        className="link-primary"
                        onClick={() => navigate("/login")}
                      >
                        Sign In
                      </a>
                    </div>
                    <div className="form-group mt-3 ">
                      <label>Full Name</label>
                      <input
                        type="text"
                        name="name"
                        value={customer.name}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                        placeholder="e.g Ram Kumar"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label>Mobile Number</label>
                      <input
                        type="text"
                        name="mobileNo"
                        value={customer.mobileNo}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                        placeholder="Mobile Number"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label>Email address</label>
                      <input
                        type="email"
                        name="emailId"
                        value={customer.emailId}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                        placeholder="Email Address"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label>Password</label>
                      <input
                        type="password"
                        name="password"
                        value={customer.password}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                        placeholder="Password"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label>Confirm Password</label>
                      <input
                        type="password"
                        name="confirm password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="form-control mt-1"
                        placeholder="Confirm Password"
                      />
                    </div>
                    <div className="d-grid gap-2 mt-3">
                      <button
                        onClick={saveCustomer}
                        type="submit"
                        className="btn btn-primary text-white "
                      >
                        {isLoading ? (
                          <div
                            class="spinner-border text-white "
                            role="status"
                            style={{
                              fontSize: "12px",
                              width: "18px",
                              height: "18px",
                            }}
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
