import { Link, useNavigate } from "react-router-dom";
import LoginService from "../login/LoginService";
import { useState, useContext } from "react";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import swal from "sweetalert";

import {
  ACCESS_TOKEN,
  USER_ROLE,
  SESSION_ID,
  USER_INFO,
  CUSTOMER_ID,
  BASE_URL,
  GET_CART_COUNT,
  DEBUG_LOG_ENABLED,
  generateSmsOtp,
  validateSmsOtp,
  DOCTOR_ID,
  BACKGROUNDIMAGE,
  ERROR_MSG,
  CART_COUNT,
} from "../Constant";
import { UserContext } from "../App";
import axios from "axios";
import { increment, handleLoggin } from "../actions";
import { useDispatch } from "react-redux";
import Otpscren from "./Otpscren.css";
import LoginScrollpages from "./LoginScrollpages";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Toaster, toast } from "sonner";
export default function Login() {
  const dispatch = useDispatch();
  //const {state, dispatch} = useContext(UserContext);
  //const history = useHistory();
  // test

  // let [authMode, setAuthMode] = useState("signin");
  // const changeAuthMode = () => {
  //   setAuthMode(authMode === "signin" ? "signup" : "signin");
  // };
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [customer, setCustomer] = useState({
    username: "",
    password: "",
    idToken: "",
    //mobileNo: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (e) => {
    const value = e.target.value;
    setCustomer({ ...customer, [e.target.name]: value });
  };

  const loginCustomer = (e) => {
    e.preventDefault();
    if (customer.username === "" || customer.password === "") {
      toast.warning("Please enter fileds");
    } else {
      loginData(customer.username, customer.password, null);
      // generateOtp(customer.username);
    }
  };

  const loginOtp = (e) => {
    e.preventDefault();
    if (customer.username === "" || customer.password === "") {
      toast.warning("Please enter fileds");
    } else {
      validateOtp(customer.username, customer.password);
    }
  };

  const sessionId = localStorage.getItem(SESSION_ID);

  const loginData = async (username, password, idToken) => {
    setIsLoading(true);
    try {
      const loginData = JSON.stringify({
        pubInfo: {
          sessionId: sessionId,
        },
        request: {
          busiParams: idToken
            ? { idToken: idToken }
            : {
                username: username,
                password: password,
              },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      // console.log("loginData", loginData);

      let response = await axios.post(BASE_URL + "login", loginData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
        },
      });

      const res = response?.data?.respData?.respMsg;
      setData(res);
      // console.log(response, "ff");
      if (response?.data?.respData?.code == 1111 || res == null) {
        toast.error("Email or Password entered wrong ");
      } else {
        // console.log(res, "ffff");
        localStorage.setItem(USER_INFO, JSON.stringify(res));
        localStorage.setItem(ACCESS_TOKEN, res?.accessToken);
        localStorage.setItem(CUSTOMER_ID, res?.customerId);
        localStorage.setItem(USER_ROLE, res?.role);

        getCartCountData(res?.customerId, res?.accessToken);
        dispatch(handleLoggin(true));
        navigate("/", { replace: true });
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error(ERROR_MSG);
    }
  };
  const getCartCountData = async (cstId, access_token) => {
    try {
      const getCartCountData = JSON.stringify({
        pubInfo: {
          sessionId: sessionId,
        },
        request: {
          busiParams: {
            customerId: cstId,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      //const response = await LoginService.getCartCount(getCartCountData,access_token);
      // dispatch(increment(response.data.respData.respMsg.cartCount));

      let res = await axios.post(BASE_URL + GET_CART_COUNT, getCartCountData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      });

      const count = res?.data?.respData?.respMsg?.cartCount;
      dispatch(increment(count));
      localStorage.setItem(CART_COUNT, count);
    } catch (err) {
      // swal(err);
      if (DEBUG_LOG_ENABLED) {
        console.log(err);
      }
    }
  };
  const generateOtp = async (username) => {
    try {
      const DataOtp = JSON.stringify({
        pubInfo: {
          sessionId: sessionId,
        },
        request: {
          busiParams: {
            username: username,
            password: "",
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      const res = await axios.post(BASE_URL + generateSmsOtp, DataOtp, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      swal(error);
    }
  };

  const validateOtp = async (username, password) => {
    try {
      const validateSmsOtpData = JSON.stringify({
        pubInfo: {
          sessionId: sessionId,
        },
        request: {
          busiParams: {
            username: username,
            password: password,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      const response = await axios.post(
        BASE_URL + validateSmsOtp,
        validateSmsOtpData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
          },
        }
      );

      setData(response.data.respData.respMsg);

      localStorage.setItem(
        USER_INFO,
        JSON.stringify(response.data.respData.respMsg)
      );

      localStorage.setItem(
        ACCESS_TOKEN,
        response.data.respData.respMsg.accessToken
      );

      localStorage.setItem(
        CUSTOMER_ID,
        response.data.respData.respMsg.customerId
      );
      localStorage.setItem(USER_ROLE, response.data.respData.respMsg.role);
      getCartCountData(
        response.data.respData.respMsg.customerId,
        response.data.respData.respMsg.accessToken
      );

      dispatch(handleLoggin(true));
      if (DEBUG_LOG_ENABLED) {
        console.log(response.data);
      }
      // {navigate===null? ( navigate('/')): (navigate(-1))}
      navigate(-1);
    } catch (error) {
      swal(error);
    }
  };

  const EnteOtp = () => {
    generateOtp(customer.username);
  };

  const [country, setcountry] = useState();

  // const location=()=>{

  //  if ("geolocation" in navigator) {
  //    // Geolocation is available
  //    navigator.geolocation.getCurrentPosition(
  //      function (position) {
  //        const latitude = position.coords.latitude;
  //        const longitude = position.coords.longitude;

  //        // Use a reverse geocoding service (e.g., Nominatim) to get the country name
  //        const geocodingApiUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;

  //        fetch(geocodingApiUrl)
  //          .then((response) => response.json())
  //          .then((data) => {
  //            const country = data.address.country;
  //            console.log(`User is in: ${country}`);
  // setcountry(country);
  //            // You can now use the "country" variable for further processing.
  //          })
  //          .catch((error) => {
  //            console.error("Error fetching country information:", error);
  //          });
  //      },
  //      function (error) {
  //        // Handle any errors that may occur during geolocation
  //        switch (error.code) {
  //          case error.PERMISSION_DENIED:
  //            console.error("User denied the request for Geolocation.");
  //            break;
  //          case error.POSITION_UNAVAILABLE:
  //            console.error("Location information is unavailable.");
  //            break;
  //          case error.TIMEOUT:
  //            console.error("The request to get user location timed out.");
  //            break;
  //          case error.UNKNOWN_ERROR:
  //            console.error("An unknown error occurred.");
  //            break;
  //        }
  //      }
  //    );
  //  } else {
  //    // Geolocation is not available in this browser
  //    console.error("Geolocation is not available in this browser.");
  //  }

  // }

  return (
    <div
      style={{
        backgroundImage: BACKGROUNDIMAGE,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100hv",
      }}
      className="bg-black "
    >
      <Toaster position="top-right" richColors={true} />
      <div className="container py-3 mt-5 pt-5  ">
        <div className="card   rounded-3 text-black">
          <div className="row  ">
            <LoginScrollpages />
            <div className="col-md-6 col-lg-6 col-xl-4 offset-xl-1">
              <div className="align-center    ">
                <div className="d-flex flex-column  align-items-center">
                  <div className="p-sm-5 ">
                    <div className="Auth-form-content">
                      <h3 className="text-center pt-3 pt-sm-0">Sign In</h3>
                      <div className="text-center">
                        Not registered yet ?
                        <a href="#/signup" onClick={() => navigate("/signup")}>
                          {" "}
                          Sign Up
                        </a>
                      </div>
                      <h1> {country} </h1>

                      <div className=" gap-2 mt-3 px-5 px-lg-3">
                        <div className="form-group mt-3">
                          <label>Email address</label>
                          <input
                            type="username"
                            name="username"
                            value={customer.username}
                            onChange={(e) => handleChange(e)}
                            className="form-control mt-1"
                            placeholder="Enter username /email"
                            maxLength="100"
                          />
                        </div>
                        <div className="form-group mt-3">
                          <label>Password</label>
                          <input
                            type="password"
                            name="password"
                            value={customer.password}
                            onChange={(e) => handleChange(e)}
                            className="form-control mt-1"
                            placeholder="Enter password"
                            maxLength="100"
                          />
                        </div>
                        <div className="d-grid gap-2 mt-4">
                          <button
                            onClick={loginCustomer}
                            type="submit"
                            className="btn btn-primary text-white"
                          >
                            {isLoading ? (
                              <div
                                class="spinner-border text-white "
                                role="status"
                                style={{
                                  fontSize: "12px",
                                  width: "18px",
                                  height: "18px",
                                }}
                              >
                                <span class="sr-only">Loading...</span>
                              </div>
                            ) : (
                              "Submit"
                            )}
                          </button>

                          {/* <button
                            // onClick={location}
                            type="submit"
                            className="btn btn-primary"
                          >
                            location
                          </button> */}
                        </div>

                        <p className="text-center mt-2">
                          Forgot{" "}
                          <Link
                            to="/forgotpassword"
                            state={{ email: customer.username }}
                          >
                            password ?
                          </Link>
                        </p>
                      </div>

                      <div className=" d-flex justify-content-center p-5">
                        <GoogleLogin
                          onSuccess={(credentialResponse) => {
                            loginData("", "", credentialResponse.credential);
                            // console.log(
                            //   credentialResponse.credential,
                            //   "fffgoo"
                            // );

                            var decoded = jwt_decode(
                              credentialResponse.credential
                            );

                            // console.log(decoded, " fffdecode");
                          }}
                          onError={() => {
                            console.log("Login Failed");
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const otpScreen = () => {
  <div
    class="modal fade"
    id="staticBackdrop"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary  ">
          <h4 class="modal-title text-white  " id="staticBackdropLabel">
            Entet OTP
          </h4>
          <button
            type="button"
            class="btn-close  "
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="d-flex justify-content-center h5">
          Please enter tne code we just srnt to
        </div>
        <div class="d-flex justify-content-center ">88888888888 to procced</div>
        <div class="d-flex justify-content-center mx-5 pt-5">
          {/* <input
                            type="text"
                            name="Otp"
                            //  value={customer.emailId}
                            className="form-control text-center mx-5"
                            
                            style={{outline: "none" }}
                            maxLength="4"
                            placeholder=" 0      0      0        0 "
                          /> */}
          <div className="divOuter">
            <div className="divInner fs-5">
              <input
                className="partitioned"
                type="text"
                //  maxLength="6"
              />
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-center mt-2  pb-5">
          <a class="text-decoration-none">Resend OTP</a>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button type="button" class="btn bg-primary">
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>;
};
