export const TOGGLE_EDIT_MODE = "TOGGLE_EDIT_MODE";

// Action creator to toggle the edit mode
export const toggleEditMode = () => {
  return {
    type: TOGGLE_EDIT_MODE,
  };
};

const initialState = {
  editMode: false,
};

const editReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_EDIT_MODE:
      return {
        ...state,
        editMode: !state.editMode, // Toggle the editMode state
      };
    default:
      return state;
  }
};

export default editReducer;
