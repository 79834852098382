import React, { useState, useEffect } from "react";
import { BASE_URL_IMG, CATITEM } from "../Constant";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SmallGridCatType = ({ topProducts, showEdit, navigate, nama }) => {
  const [datas, setDatas] = useState([]);
  useEffect(() => {
    setDatas(topProducts);
  }, [topProducts]);

  const data = (item) => {
    localStorage.setItem("myData", JSON.stringify(item));
    localStorage.setItem("myDataview", nama);
    navigate("/itemsviewmore");
  };
  return (
    <>
      <div
        style={{ backgroundColor: "rgba(247, 95, 1, 0.14)" }}
        className="d-flex justify-content-cneter mb-4 mt-4 align-items-center"
      >
        <p
          className="px-4 fs-4 fw-bold"
          style={{
            margin: 0,
          }}
        >
          Top Categories
        </p>
      </div>
      <div className=" px-3 px-sm-3">
        {datas?.length > 0 && (
          <div className="row">
            {datas?.map((item, idx) => (
              <div key={idx} className="col-6 " style={{ textAlign: "center" }}>
                {showEdit && (
                  <div className="d-sm-flex mb-1">
                    <Link
                      className="btn btn-outline-primary  "
                      style={{ width: "auto", height: "36px" }}
                      to={"/addcatitem"}
                      // replace
                      onClick={() =>
                        localStorage.setItem(CATITEM, JSON.stringify(item))
                      }
                    >
                      <FontAwesomeIcon icon="fa-solid  fa-pen-to-square" />
                      Edit
                    </Link>
                  </div>
                )}
                <img
                  onClick={() => data(item)}
                  className="card-img-top bg-dark "
                  style={{
                    borderRadius: "8px",
                    cursor: "pointer",
                    boxShadow: "1px 0px 0px rgba(0, 0, 0, 0.2)",
                    objectFit: "cover",
                  }}
                  height={window.innerWidth > 576 ? "220px" : "160px"}
                  width="136px"
                  alt={item.title}
                  src={BASE_URL_IMG + item.iconURL}
                />
                <p
                  style={{ color: "black", fontSize: "14px", marginTop: "8px" }}
                >
                  {item.title}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
export default SmallGridCatType;
