import Banner from "./Banner";
import FeatureProduct from "./FeatureProduct";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, createContext } from "react";

import ProductListCard from "./ProductListCard";
import ProductGridCard from "./ProductGridCard";
import {
  ROLE,
  USER_ROLE,
  TOP_BANNER,
  TOP_PRODUCT,
  CARD_VIEW,
  ABOUT_US_VIEW,
  you_tube_view,
} from "../Constant";
import ProductItemCart from "../landing/ProductItemCart";
import AboutUS from "../about/AboutUs";
import YouTubecard from "./YouTubecard";
import InstagramPosts from "./InstagramPosts";
import Googlereview from "./Googlereview";
import SmallGridCatType from "./SmallGridCatType";
import { useSelector } from "react-redux";
function Landing({ listData }) {
  const navigate = useNavigate();
  const editMode = useSelector((state) => state.editMode.editMode);

  return (
    <div style={{ minHeight: "700px" }}>
      {/* <div class="position-absolute top-100 start-100 translate-middle">
        <button>
          <FontAwesomeIcon
            icon="fa-brands fa-whatsapp"
            style={{ width: 50, height: 50 }}
          />
        </button>
      </div> */}

      {/* <ScrollToTopOnMount /> */}

      {listData?.map((item, index) => (
        <div className="" style={{ overflow: "hidden" }}>
          {
            <>
              {
                <div>
                  {item?.viewType === TOP_BANNER ? (
                    <>
                      <Banner product={item} />
                      <div className="d-md-none">
                        <SmallGridCatType
                          topProducts={listData?.filter(
                            (item) => item?.viewType === TOP_PRODUCT
                          )}
                          showEdit={editMode}
                          navigate={navigate}
                          nama={TOP_PRODUCT}
                        />
                      </div>
                    </>
                  ) : (
                    <div>
                      <div>
                        {item?.viewType === TOP_PRODUCT ? (
                          <div className="d-none d-md-block">
                            <ProductGridCard
                              item={item}
                              navigate={navigate}
                              showEdit={editMode}
                              nama={TOP_PRODUCT}
                            />
                          </div>
                        ) : (
                          <div>
                            <div>
                              {item?.viewType === CARD_VIEW ? (
                                <div>
                                  {/* <ProductListCard
                                    item={item}
                                    showEdit={showEdit}
                                  /> */}
                                </div>
                              ) : (
                                <div>
                                  {item?.viewType === ABOUT_US_VIEW ? (
                                    <div>
                                      {editMode ? (
                                        <div>
                                          {/* <ProductListCard
                                            item={item}
                                            showEdit={showEdit}
                                          /> */}
                                        </div>
                                      ) : (
                                        <div></div>
                                      )}
                                    </div>
                                  ) : (
                                    <div>
                                      {item?.viewType === you_tube_view ? (
                                        <div>
                                          <div>
                                            <YouTubecard
                                              item={item}
                                              showEdit={editMode}
                                              navigate={navigate}
                                            />
                                          </div>
                                        </div>
                                      ) : (
                                        <div>
                                          {item?.viewType ===
                                          "InstagramPosts" ? (
                                            <div>
                                              <div>
                                                {/* <InstagramPosts
                                                  item={item}
                                                  showEdit={showEdit}
                                                  navigate={navigate}
                                                /> */}
                                                {/* <MagicScroll
                                                  images={images}
                                                  speed={2400}
                                                  autoplay={1000}
                                                  items={4}
                                                /> */}
                                              </div>
                                            </div>
                                          ) : (
                                            <div>
                                              {item?.viewType ===
                                              "Googlereview" ? (
                                                <div>
                                                  <div>
                                                    <Googlereview
                                                      item={item}
                                                      showEdit={editMode}
                                                      navigate={navigate}
                                                    />
                                                  </div>
                                                </div>
                                              ) : (
                                                <div></div>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              }
            </>
          }
        </div>
      ))}
    </div>
  );
}

export default Landing;
