import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import ReviewCartItem from "./ReviewCartItem";
import { useState, useEffect } from "react";
import {
  SESSION_ID,
  CUSTOMER_ID,
  BASE_URL,
  ChangeOrderStatus,
  ACCESS_TOKEN,
  DEBUG_LOG_ENABLED,
  PRIMARY_LIGHT,
  ERROR_MSG,
  CASH_ON_DELVIERY,
} from "../Constant";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { toast } from "sonner";

function OrderHistoryItem({
  id,
  product,
  oldaddressData,
  addressData,
  showEdit,
  getHistory,
}) {
  console.log("xkks", product);
  var address = "";
  if (addressData !== "") {
    address = JSON.parse(addressData);
  }

  const navigate = useNavigate();
  const [Loaders, setLoaders] = useState(false);
  const [completeLoaders, setCompleteLoaders] = useState(false);
  const [noteLoader, setNoteLoader] = useState(false);
  const [localStatus, setLocalStatus] = useState(0);
  const [notes, setNotes] = useState("");

  const askDelete = (num) => {
    toast.info(
      <div>
        <span className="fw-semibold fs-6">
          {`Are you sure about to ${
            num == 3 ? "complete" : "remove"
          } this order`}
        </span>
        <div
          className="d-flex justify-content-center mt-2"
          style={{ gap: "30px" }}
        >
          <button
            className="btn btn-primary text-white"
            style={{
              height: "30px",
              margin: 0,
              display: "flex",
              fontSize: 12,
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={async () => {
              cancelOrder(num);
              toast.dismiss();
            }}
          >
            Yes
          </button>
          <button
            className="btn btn-outline-primary"
            style={{
              height: "30px",
              margin: 0,
              fontSize: 11,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => toast.dismiss()}
          >
            No
          </button>
        </div>
      </div>
    );
  };

  const cancelOrder = async (num) => {
    if (num === 2) {
      setLoaders(true);
    } else {
      setCompleteLoaders(true);
    }

    try {
      const canceleData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            orderId: product.id,
            orderStatus: num,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      if (DEBUG_LOG_ENABLED) {
        console.log("canceleData : " + canceleData);
      }

      // const response = await GetOrderService.changeOrderStatus(canceleData);
      let response = await axios.post(
        BASE_URL + ChangeOrderStatus,
        canceleData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        }
      );
      getHistory();
      toast.success(
        num === 2
          ? showEdit
            ? "Order cancelled !"
            : "Your booking has been cancelled !"
          : "Order completion done !"
      );
      setLoaders(false);
      setCompleteLoaders(false);
    } catch (err) {
      setLoaders(false);
      setCompleteLoaders(false);
      toast.error(ERROR_MSG);
    }
  };

  const saveNote = async () => {
    if (notes?.length > 0) {
      toast.warning("Enter notes");
      return;
    }
    setNoteLoader(true);

    try {
      const canceleData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            orderId: product.id,
            note: notes,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      if (DEBUG_LOG_ENABLED) {
        console.log("canceleData : " + canceleData);
      }

      // const response = await GetOrderService.changeOrderStatus(canceleData);
      let response = await axios.post(
        BASE_URL + ChangeOrderStatus,
        canceleData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        }
      );
      getHistory();
      toast.success("Note save successfully");
      setNoteLoader(false);
    } catch (err) {
      setNoteLoader(false);
      toast.error(ERROR_MSG);
    }
  };
  // console.log("Puja ", product);

  const pujaDate = product?.details?.map((item) => item?.date);

  return (
    <div className="card border-1  shadow-sm rounded-3 mb-3">
      <div
        className="card-header py-sm-3 py-2 rounded-3 border-0 mx-3 mt-3"
        style={{ backgroundColor: "#fef2e7" }}
      >
        <div className="d-flex justify-content-between">
          <div className="d-flex justify-content-between  gap-3">
            <span
              className="fw-bold text-black  fs-sm-5 my-auto"
              style={{ letterSpacing: 0.6 }}
            >
              {address === "" ? oldaddressData[0]?.name : address?.name}
            </span>
            <div className="d-flex gap-2" style={{ flexWrap: "wrap" }}>
              {pujaDate?.map((date, idx, arr) => (
                <div
                  className="text-muted"
                  key={idx}
                  style={{ overflow: "hidden" }}
                >
                  Puja{arr.length > 1 ? ` ${idx + 1}` : ""} :{" "}
                  <span className="fw-bold text-black fs-sm-5  my-auto">
                    {moment(date).format("DD MMM YYYY - ddd hh:mm a")}
                  </span>
                </div>
              ))}
            </div>

            {/* {pujaDates?.map((date, index) => (
    <div key={index}>
      <span className="text-muted">Puja Date {index + 1}</span> :{" "}
      {moment(date).format("DD MMM YYYY dddd hh:mm a")}
    </div>
  ))} */}
          </div>
          <div className="col-auto">
            <span className="fw-bold  d-sm-block d-none fw-medium text-black  fs-sm-5 my-auto">
              Order ID: {id}
            </span>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row gx-2 gy-3">
          <div className="col-md-5">
            <p
              style={{ margin: 0, letterSpacing: 0.6 }}
              className="fw-semibold text-muted"
            >
              Shipping Address
            </p>
            {address === "" ? (
              <div className="vstack text-dark small">
                <span>
                  {oldaddressData[0]?.id + "" + oldaddressData[0]?.addressLine1}
                </span>
                <span>
                  {oldaddressData[0]?.city +
                    " " +
                    oldaddressData[0]?.state +
                    "" +
                    oldaddressData[0]?.country +
                    " " +
                    oldaddressData[0]?.pincode}
                </span>
                <span>{"Mobile No : " + oldaddressData[0]?.mobileNo}</span>
                <span>{"Email Id : " + oldaddressData[0]?.addressLine2}</span>
              </div>
            ) : (
              <div className="vstack text-dark small">
                <span>{address?.addressLine1}</span>
                <span>
                  {address?.city +
                    " " +
                    address?.state +
                    " " +
                    // address?.country +
                    " " +
                    address?.pincode}
                </span>

                <span>{"Mobile No: " + address?.mobileNo}</span>
                <span>{"Email Id : " + address?.addressLine2}</span>
              </div>
            )}
          </div>
          <div className="col-md-4">
            <p
              style={{ margin: 0, letterSpacing: 0.6 }}
              className="fw-semibold text-muted"
            >
              Payment Method{" "}
              <span className="d-block d-sm-none  fw-medium text-black small my-auto">
                Order ID: {id}
              </span>
            </p>
            <div className="text-success small">
              <span className="small">{product.paymentId}</span>
            </div>
            <div className="small">
              {product.currency === "USD"
                ? "Sub Total : " + "$" + product.amount
                : "Sub Total : " + "₹" + product.amount}
            </div>

            <div className="small">
              {product.currency === "USD"
                ? "Total : " + "$" + product.amount
                : "Total : " + " " + "₹" + product.amount}
            </div>
          </div>
          <div className="col-md-3">
            <p
              style={{ margin: 0, letterSpacing: 0.6 }}
              className="fw-semibold text-muted"
            >
              Status
            </p>
            <div
              className={
                product.orderStatus === 2
                  ? "text-danger"
                  : product.orderStatus === 3
                  ? "text-success"
                  : product.orderStatus === 1 ||
                    product?.paymentId !== CASH_ON_DELVIERY
                  ? ""
                  : "text-warning"
              }
              style={{
                color:
                  product.orderStatus === 1 ||
                  product?.paymentId !== CASH_ON_DELVIERY
                    ? "blue"
                    : "",
                letterSpacing: 1,
              }}
            >
              <span className="fw-bold small ">
                {/* {product.currency === "USD" ? (
                  "PLACED"
                ) : ( */}
                <>
                  {product.orderStatus === 2
                    ? "CANCELLED"
                    : product.orderStatus === 3
                    ? "COMPLETED"
                    : product.orderStatus === 1 ||
                      product?.paymentId !== CASH_ON_DELVIERY
                    ? "PLACED"
                    : product.orderStatus === 0
                    ? "PAYMENT PENDING"
                    : product.orderStatus === -1
                    ? "PAYMENT FAILED"
                    : ""}
                </>
                {/* )} */}
              </span>
            </div>
          </div>
        </div>
        <hr className="text-muted" />
        <div className="row row-cols-1 row-cols-md-2 g-3">
          {product?.details?.map((it) => (
            <div className="col">
              <ReviewCartItem product={it} currency={product.currency} />
            </div>
          ))}
        </div>
        <div className="col-auto mt-3">
          {product.orderStatus === 2 ? null : product.orderStatus ===
            3 ? null : (
            <button
              className="btn btn-sm btn-danger"
              onClick={() => askDelete(2)}
            >
              Cancel
              {Loaders && (
                <div
                  className="spinner-border text-light ms-2 "
                  style={{ height: 14, width: 14, fontSize: "10px" }}
                  role="status"
                ></div>
              )}
            </button>
          )}
          {showEdit &&
            product.orderStatus !== 2 &&
            product.orderStatus !== 3 && (
              <button
                className="btn ms-2 btn-sm btn-success"
                onClick={() => askDelete(3)}
              >
                Complete
                {completeLoaders && (
                  <div
                    className="spinner-border text-light ms-2 "
                    style={{ height: 14, width: 14, fontSize: "10px" }}
                    role="status"
                  ></div>
                )}
              </button>
            )}
        </div>
      </div>

      <div className="card-footer d-sm-flex justify-content-between  align-items-center g-4 small border-0 py-sm-2 py-1 bg-transparent text-muted">
        <div>
          Order Date & Time :{" "}
          <span className="fw-bold text-muted">
            {moment(product?.orderDate).format("DD MMM YYYY - ddd hh:mm a")}
          </span>
        </div>
        {/* {!showEdit && (
          <div className="d-flex my-sm-1 my-2" style={{ maxWidth: "450px" }}>
            <textarea
              name="message"
              // value={}
              // onChange={}
              placeholder={"Leave a note"}
              className=" border-primary p-2 bg-white  placeholder-secondary rounded-2 text-muted"
              rows={1}
              style={{ width: "450px" }}
            />
            <button
              className="btn ms-2 btn-sm btn-info align-items-center justify-content-center d-flex rounded-2"
              onClick={() => saveNote()}
              style={{ width: "70px", backgroundColor: PRIMARY_LIGHT }}
            >
              {noteLoader ? (
                <div
                  className="spinner-border text-primary "
                  style={{ height: 14, width: 14, fontSize: "10px" }}
                  role="status"
                ></div>
              ) : (
                "Save"
              )}
            </button>
          </div>
        )} */}
        {/* <div>
          <p className="fw-bold " style={{ margin: 0, maxWidth: "550px" }}>
            Note : Your courire has been parceled Your courire has been
            parceledYour courire has been parceled Your courire has been
            parceled Your courire has been parceled
          </p>
        </div> */}
      </div>
    </div>
  );
}

export default OrderHistoryItem;
