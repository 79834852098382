import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import {
  SESSION_ID,
  CUSTOMER_ID,
  GET_CLIENT_ID,
  ACCESS_TOKEN,
  BASE_URL,
  ADDRESSLIST,
  ADDRESSDATA,
  getCustomerAddressByUserId,
  addCustomerAddress,
  removeCustomerAddress,
  PAYPAL_CLIENT_ID,
  PAYPAL_SENDBOX_CLIENT_ID,
  DEBUG_LOG_ENABLED,
  ID_REZORPAY,
  PRIMARY_LIGHT,
  ERROR_MSG,
} from "../Constant";
import { useState, useEffect } from "react";
import swal from "sweetalert";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import RemoveAddressService from "../oder_review/RemoveAddressService";
import axios from "axios";
import CheckoutStepper from "../components/CheckoutStepper";
import { toast, Toaster } from "sonner";
function AddAddress() {
  const dispatch = useDispatch();
  const [customerAddress, setCustomerAddress] = useState([]);
  const [addressId, setAddressId] = useState("");
  const [show, setShow] = useState(true);
  const [clientID, setClientID] = useState("");
  const [addressShow, setAddressShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loaders, setLoaders] = useState(false);

  const getAddressLists = async () => {
    try {
      const customerData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            customerId: customerId,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      let response = await axios.post(
        BASE_URL + getCustomerAddressByUserId,
        customerData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        }
      );

      const addressList = response.data.respData.respMsg;
      // console.log(addressList, "f");
      setCustomerAddress(addressList);
      const defaultAddress =
        addressList.find((addr) => addr?.defaultAddress) || addressList[0];
      if (defaultAddress) {
        handleAddressIdchang(defaultAddress);
      }
      localStorage.setItem(ADDRESSLIST, JSON.stringify(addressList));

      if (addressList.length === 0) {
        setAddressShow(true);
      } else {
        setAddressShow(false);
      }
      setLoaders(false);
    } catch (err) {
      if (err.response.status == 401) {
        navigate("/login");
      } else {
        toast.error(ERROR_MSG);
        setLoaders(false);
      }
    }
  };
  const handleAddressIdchang = (item) => {
    // const value = e.target.value;
    localStorage.setItem(ADDRESSDATA, JSON.stringify(item));
    setAddressId(item?.id);
  };

  const customerId = localStorage.getItem(CUSTOMER_ID);

  const [customer, setCustomer] = useState({
    customerId: customerId,
    name: "",
    addressLine1: "",
    addressLine2: "",
    landmark: "",
    city: "",
    state: "",
    purpose: "",
    pincode: "",
    country: "",
    mobileNo: "",
    defaultAddress: false,
  });

  // console.log("customer ", customer);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCustomer({
      ...customer,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const navigate = useNavigate();
  //const responseJson = navigate.getParam("myJSON");

  const [catItemList, setCartItemList] = useState([]);

  const reviewOrder = (e) => {
    e.preventDefault();
    // if (addressId === "" && clientID === "") {
    //   toast.warning("Please select your address");
    // } else {
    //   navigate("/orderreview");
    // }
    if (addressId === "") {
      toast.warning("Please select your address");
    } else {
      navigate("/orderreview");
    }
  };

  const saveAddress = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      if (
        customer.name === "" ||
        customer.mobileNo === "" ||
        customer.addressLine1 === "" ||
        customer.addressLine2 === "" ||
        customer.city === "" ||
        customer.state === "" ||
        customer.country === "" ||
        customer.pincode === ""
      ) {
        toast.warning(" Please enter all fields ");
      } else {
        const customerData = JSON.stringify({
          pubInfo: {
            sessionId: localStorage.getItem(SESSION_ID),
          },
          request: {
            busiParams: {
              customerId: customerId,
              name: customer.name,
              addressLine1: customer.addressLine1,
              addressLine2: customer.addressLine2,
              city: customer.city,
              state: customer.state,
              purpose: customer.purpose,
              country: customer.country,
              pincode: customer.pincode,
              landmark: customer.landmark,
              mobileNo: customer.mobileNo,
              defaultAddress: customer.defaultAddress,
            },
            isEncrypt: false,
            transactionId: "897987987989",
          },
        });

        let response = await axios.post(
          BASE_URL + addCustomerAddress,
          customerData,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
              "Access-Control-Allow-Headers": "Content-Type",
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
            },
          }
        );

        toast.success(" " + response.data.respData.message);
        setLoader(false);

        setCustomer({
          customerId: customerId,
          name: "",
          addressLine1: "",
          addressLine2: "",
          landmark: "",
          city: "",
          state: "",
          purpose: "",
          pincode: "",
          country: "",
          mobileNo: "",
          defaultAddress: false,
        });
        setAddressShow(false);
        getAddressLists();
      }
    } catch (err) {
      if (err.response.status == 401) {
        navigate("/login");
      } else {
        toast.error(ERROR_MSG);
        setLoader(false);
      }
    }
  };

  const removeAddress = async (item) => {
    try {
      const removeAddressItem = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            customerId: localStorage.getItem(CUSTOMER_ID),
            addressId: item.id,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      let response = await axios.post(
        BASE_URL + removeCustomerAddress,
        removeAddressItem,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        }
      );

      toast.success(" " + response.data.respData.message);
      getAddressLists();
    } catch (err) {
      if (err.response.status == 401) {
        navigate("/login");
      } else {
        toast.error(ERROR_MSG);
        if (DEBUG_LOG_ENABLED) {
          console.log(err);
        }
      }
    }
  };

  const removeItem = (item) => {
    toast.info(
      <div>
        <span className="fw-semibold fs-6">
          Are you sure about to remove this address
        </span>
        <div
          className="d-flex justify-content-center mt-2"
          style={{ gap: "30px" }}
        >
          <button
            className="btn btn-primary text-white"
            style={{
              height: "30px",
              margin: 0,
              display: "flex",
              fontSize: 12,
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={async () => {
              removeAddress(item);
              toast.dismiss();
            }}
          >
            Yes
          </button>
          <button
            className="btn btn-outline-primary"
            style={{
              height: "30px",
              margin: 0,
              fontSize: 11,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => toast.dismiss()}
          >
            No
          </button>
        </div>
      </div>
    );
  };

  const getClient = async () => {
    try {
      const getClientId = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            customerId: localStorage.getItem(CUSTOMER_ID),
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      // const response = await OrderReviewService.create(setOrderItem);
      let response = await axios.post(BASE_URL + GET_CLIENT_ID, getClientId, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });
      setClientID(response.data.respData.respMsg.paypalClientId);
      localStorage.setItem(
        PAYPAL_CLIENT_ID,
        response?.data?.respData?.respMsg?.paypalClientId
      );
      localStorage.setItem(
        PAYPAL_SENDBOX_CLIENT_ID,
        response?.data?.respData?.respMsg?.sandboxPaypalClientId
      );

      localStorage.setItem(
        ID_REZORPAY,
        response?.data?.respData?.respMsg?.razorpayKeyId
      );

      // response?.data?.respData?.respMsg?.paypalClientId;
      // response?.data?.respData?.respMsg?.paypalSecretId;
      // response?.data?.respData?.respMsg?.razorpayKeyId;
      // response?.data?.respData?.respMsg?.razorpayKeySecret;
      // response?.data?.respData?.respMsg?.sandboxPaypalClientId;
      // response?.data?.respData?.respMsg?.sandboxPaypalSecretId;
      // response?.data?.respData?.respMsg?.sandboxRazorpayKeyId;
      // response?.data?.respData?.respMsg?.sandboxRazorpayKeySecret;
      // response?.data?.respData?.respMsg?.twoPayMerchantId;
      //       response?.data?.respData?.respMsg?.twoPaySecretKey;
    } catch (err) {
      toast.error(ERROR_MSG);
      if (DEBUG_LOG_ENABLED) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    const customerId = localStorage.getItem(CUSTOMER_ID);
    if (customerId === undefined || customerId === null) {
      localStorage.setItem(CUSTOMER_ID, "");
      navigate("/login");
    } else {
      getClient();
      setLoaders(true);
      getAddressLists();
    }
  }, []);

  return (
    <div className="  align-items-center container  py-5 px-xl-5 mx-auto mt-5 maxWidth 900px">
      <Toaster position="top-right" richColors={true} />
      <div className="wrapper">
        <div className="col-lg-9">
          <div className="d-flex flex-column ">
            <ScrollToTopOnMount />

            <div className="row">
              <div className="col-md-12">
                <CheckoutStepper />
              </div>
            </div>

            <div class="card mb-4 bg-transparent ">
              <div class="card-body p-3 ">
                <form>
                  <div class="form-group">
                    <label className="h4">Select Address</label>
                    {customerAddress?.length > 0 ? (
                      customerAddress?.map((item, index) => (
                        <div
                          class="d-flex flex-row justify-content-between card shadow-sm text-black fw-medium my-3  p-2 rounded-3"
                          style={{ backgroundColor: PRIMARY_LIGHT }}
                        >
                          <div
                            class="form-check"
                            id="form-check1"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleAddressIdchang(item)}
                          >
                            <input
                              class="form-check-input"
                              id={`flexRadioDefault${index}`} // Unique ID for each input
                              checked={addressId === item?.id}
                              style={{ cursor: "pointer" }}
                              onChange={() => handleAddressIdchang(item)}
                              type="radio"
                              name="flexRadioDefault"
                            />
                            <span className="text-black fw-semibold">
                              {item.name}
                            </span>
                            <br />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault1"
                            >
                              {item.addressLine1}
                            </label>
                            <p style={{ margin: 0 }} class="form-check-label">
                              {item.city +
                                " " +
                                item.state +
                                " " +
                                item.country +
                                " " +
                                item.pincode}
                            </p>
                            <p style={{ margin: 0 }} class="form-check-label">
                              {"Mobile No : " + item.mobileNo}
                            </p>
                            <p style={{ margin: 0 }}>
                              {"Email ID : " + item.addressLine2}
                            </p>
                          </div>
                          <div>
                            <button
                              type="button"
                              class="btn btn-transparent border-0 btn-md "
                              onClick={(e) => {
                                e.preventDefault();
                                removeItem(item);
                              }}
                            >
                              <FontAwesomeIcon icon="fa-trash" />
                            </button>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p className="text-muted">
                        {loaders ? (
                          <span
                            className="spinner-border text-primary ms-2 "
                            style={{
                              height: 14,
                              width: 14,
                              fontSize: "10px",
                            }}
                            role="status"
                          ></span>
                        ) : (
                          "No address found"
                        )}
                      </p>
                    )}
                  </div>
                </form>
              </div>
            </div>

            <form class="row g-3  ">
              <div
                className="accordion accordion-flush "
                id="accordionFlushExample"
              >
                <div className="accordion-item ">
                  <h2 className="accordion-header " id="flush-headingOne">
                    <button
                      className="accordion-button collapsed rounded-2"
                      style={{
                        backgroundColor: PRIMARY_LIGHT,
                        boxShadow: "none",
                      }}
                      type="button"
                      onClick={() => setAddressShow(!addressShow)} // Toggle address show state
                      aria-expanded={addressShow} // This will toggle based on addressShow state
                      aria-controls="flush-collapseOne"
                    >
                      <span className="h4" style={{ margin: 0 }}>
                        Add new address
                      </span>
                    </button>
                  </h2>

                  <div
                    id="flush-collapseOne"
                    className={`accordion-collapse collapse ${
                      addressShow ? "show" : ""
                    }`} // Conditionally apply the "show" class
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      class="card p-4 mt-2"
                      style={{ backgroundColor: PRIMARY_LIGHT }}
                    >
                      <div class="row g-3">
                        <div class="col-md-4 ">
                          <label>Full Name</label>
                          <input
                            class="form-control mt-1"
                            id="validationServer01"
                            type="text"
                            name="name"
                            required
                            value={customer.name}
                            onChange={(e) => handleChange(e)}
                            placeholder="Full Name"
                            maxLength={100}
                          />
                        </div>
                        <div class="col-md-4 mt-3">
                          <label>Mobile Number</label>
                          <input
                            type="text"
                            name="mobileNo"
                            required="true"
                            value={customer.mobileNo}
                            onChange={(e) => handleChange(e)}
                            className="form-control mt-1"
                            placeholder="Mobile Number"
                            maxLength={12}
                          />
                        </div>
                      </div>
                      <div class="row g-3">
                        <div class="col-md-4 mt-4">
                          <label>Email</label>
                          <input
                            type="text"
                            name="addressLine2"
                            aria-required
                            value={customer.addressLine2}
                            onChange={(e) => handleChange(e)}
                            className="form-control mt-1"
                            placeholder="Enter your email"
                            maxLength="100"
                          />
                        </div>
                        <div class="col-md-4 mt-4">
                          <label>Address</label>
                          <input
                            type="text"
                            name="addressLine1"
                            required="true"
                            value={customer.addressLine1}
                            onChange={(e) => handleChange(e)}
                            className="form-control mt-1"
                            placeholder="e.g, flat no, building name ..."
                            maxLength="100"
                          />
                        </div>

                        <div class="col-md-4 mt-4">
                          <label>City</label>
                          <input
                            type="text"
                            name="city"
                            required="true"
                            value={customer.city}
                            onChange={(e) => handleChange(e)}
                            className="form-control mt-1"
                            placeholder="City"
                            maxLength="100"
                          />
                        </div>
                      </div>

                      <div class="row g-3">
                        <div class="col-md-4 mt-4">
                          <label>State</label>
                          <input
                            type="text"
                            name="state"
                            required="true"
                            value={customer.state}
                            onChange={(e) => handleChange(e)}
                            className="form-control mt-1"
                            placeholder="state"
                            maxLength="50"
                          />
                        </div>
                        <div class="col-md-4 mt-4">
                          <label>Country</label>
                          <input
                            type="text"
                            name="country"
                            required="true"
                            value={customer.country}
                            onChange={(e) => handleChange(e)}
                            className="form-control mt-1"
                            placeholder="country"
                            maxLength="20"
                          />
                        </div>
                        <div class="col-md-4 mt-4">
                          <label>Zip</label>
                          <input
                            type="numbar"
                            name="pincode"
                            required="true"
                            value={customer.pincode}
                            onChange={(e) => handleChange(e)}
                            className="form-control mt-1"
                            placeholder="pincode"
                            maxLength="10"
                          />
                        </div>
                      </div>

                      <div class="form-group mt-3">
                        <label>Sankalp (Purpose of Puja)</label>
                        <textarea
                          class="form-control mt-1"
                          name="purpose"
                          value={customer.purpose}
                          onChange={(e) => handleChange(e)}
                          placeholder="sankalp"
                          rows="2"
                          maxLength="100"
                        ></textarea>
                        <div class="col-12 pt-1">
                          <div class="form-check">
                            <input
                              name="defaultAddress"
                              className="form-check-input is-valid"
                              type="checkbox"
                              checked={customer.defaultAddress} // Use checked instead of value
                              id="invalidCheck3"
                              aria-describedby="validCheck3Feedback"
                              onChange={handleChange} // No need to wrap in an arrow function
                            />
                            <label class="form-check-label" for="invalidCheck3">
                              Mark As Default Address
                            </label>
                          </div>
                        </div>

                        <div class="d-flex justify-content-end ">
                          <button
                            className="btn btn-outline-dark mt-3"
                            for="form-check1"
                            onClick={saveAddress}
                            type="submit"
                          >
                            <FontAwesomeIcon icon="fa-floppy-disk" />{" "}
                            {loader ? (
                              <span
                                className="spinner-border text-black ms-2 "
                                style={{
                                  height: 14,
                                  width: 14,
                                  fontSize: "10px",
                                }}
                                role="status"
                              ></span>
                            ) : (
                              "Save"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="d-flex justify-content-end py-4 ">
            <button
              type="button"
              onClick={reviewOrder}
              class="btn btn-primary btn-md text-white"
            >
              Deliver To This Address
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddAddress;
