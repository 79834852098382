import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Link } from "react-router-dom";

const YouTubecard = ({ item, navigate, showEdit }) => {
  return (
    <div>
      <div class=" fw-semibold mt-sm-5 mt-md-4 mt-0 py-4">
        <div
          style={{ backgroundColor: "rgba(247, 95, 1, 0.14)" }}
          className="d-flex align-content-between align-items-center"
        >
          <p
            className="px-4 fs-4 fw-bold"
            style={{
              margin: 0,
            }}
          >
            {item.title}
          </p>

          <div class="ms-auto align-items-center pe-3 ">
            <div className="d-flex align-items-center ms-auto">
              <p
                onClick={() =>
                  navigate(
                    "products",
                    localStorage.setItem("myData", JSON.stringify(item))
                  )
                }
                style={{ margin: 0 }}
                class=" text-primary ms-auto"
              >
                See all
                <FontAwesomeIcon
                  icon="fa-solid fa-greater-than  "
                  className="ps-2"
                  fade
                />
              </p>
              {showEdit && (
                <button
                  className="btn btn-outline-primary  ms-3 "
                  onClick={() =>
                    navigate(
                      "addcat",
                      localStorage.setItem("catData", JSON.stringify(item))
                    )
                  }
                  type="submit"
                >
                  <FontAwesomeIcon icon="fa-solid fa-pen-to-square " />
                  Edit
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row row-cols-md-2  row-cols-lg-3  row-cols-xl-4 g-1  mb-2"> */}
      <div className="d-flex flex-row flex-nowrap overflow-auto gap-4 px-3 no-scrollbar mb-3">
        {item?.items?.map((it) => (
          <div
            class="card rounded-4 border-5   bg-transparent "
            style={{
              minWidth: window?.innerWidth > 600 ? 420 : 280,
              minHeight: 150,
              width: 300,
            }}
          >
            <iframe
              title="{it?.iconURL}"
              height="220"
              src={it?.iconURL}
              // src="https://drive.google.com/file/d/1pBUsRGvPxIpEjWjY3q12bUwPQZgQ5TfA/preview"

              allow="autoplay"
              allowFullScreen
              className="rounded-4 d-block"
              loading="lazy"
            ></iframe>

            <div class="card-body ">
              <h5 class="card-title">{it?.title}</h5>
              <p class="card-text">{it?.subTitle}</p>
              {showEdit ? (
                <Link
                  to={"/productdetail/" + it?.itemId}
                  onClick={() =>
                    localStorage.setItem("myProduct", JSON.stringify(it))
                  }
                >
                  <button class="btn btn-primary text-white ">
                    Video Edit
                  </button>
                </Link>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default YouTubecard;
