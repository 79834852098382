import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, button } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import swal from "sweetalert";
import Headercss from "./Header.css";
import { useDispatch } from "react-redux";
import LanguageSelector from "../reducer/LanguageSelector";
import ProductListCard from "../landing/ProductListCard";
import { ROLE, USER_ROLE } from "../Constant";
import { toggleEditMode } from "../redux/toggleReducer";
import CustomSwitch from "./CustomeSwitch";
const Header = () => {
  //const {state, dispatch} = useContext(UserContext);
  const dispatch = useDispatch();
  const counter = useSelector((state) => state.counter);
  const isLogged = useSelector((state) => state.isLogged);
  // console.log("ss", isLogged);
  const navigate = useNavigate();
  const Location = useLocation();
  const userRole = localStorage.getItem(USER_ROLE);

  //   const RenderMenu = () => {

  // const loginButton=()=>{
  //   changeNav();
  //   navigate("/login");

  // }

  //     if (isLogged) {
  //       return (
  //         <>
  //           <button
  //             to="/logout"
  //             className="btn btn-outline-light me-3  d-lg-inline"
  //             replace
  //             onClick={changeNav}
  //           >
  //             Logout
  //           </button>
  //           <button
  //             to="/profile"
  //             className="btn btn-outline-light me-3  d-lg-inline"
  //             replace
  //             onClick={changeNav}
  //           >
  //             Profile
  //           </button>
  //         </>
  //       );
  //     } else {
  //       return (
  //         <>
  //           <button
  //             //to="/login"
  //             className="btn btn-outline-light me-3  d-lg-inline"
  //             replace
  //             onClick={loginButton}

  //           >
  //             Login
  //           </button>
  //           <button
  //             to="/signup"
  //             className="btn btn-outline-light me-3  d-lg-inline"
  //             replace
  //             onClick={changeNav}
  //           >
  //             Sign Up
  //           </button>
  //         </>
  //       );
  //     }
  //   };

  const RenderMenu = () => {
    //console.log(Location);

    if (isLogged) {
      return (
        <>
          <ul className="navbar-nav me-auto mb-lg-0">
            <li className="nav-item pb-3">
              <button
                // to="/logout"
                className="btn text-light  d-lg-inline"
                replace
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={() => navigate("/logout")}
                style={{ marginLeft: -6 }}
              >
                <FontAwesomeIcon
                  icon="fa-solid fa-right-from-bracket"
                  className="pe-3 text-light"
                />
                Log Out
              </button>
            </li>
            <li className="nav-item">
              <button
                className="btn text-light d-lg-inline"
                replace
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={() => navigate("/profile")}
                style={{ marginLeft: -6 }}
              >
                <FontAwesomeIcon
                  icon="fa-solid fa-user"
                  className="pe-3 text-light "
                />
                Profile
              </button>
            </li>
          </ul>
        </>
      );
    } else {
      return (
        <>
          <ul className="navbar-nav me-auto mb-lg-0">
            <li className="nav-item pb-3">
              {/* <img src="./../icon-2.png" class="text-white" alt="..." /> */}
              <FontAwesomeIcon
                icon="fa-solid fa-user"
                className="ps-2 pe-1 text-light "
              />
              <button
                // to="/login"
                className="btn text-light   d-lg-inline"
                replace
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={() => navigate("/login")}
              >
                Login
              </button>
            </li>
            {/* <li className="nav-item">
              <button
                // to="/signup"
                className="btn text-light px-3 me-3 d-lg-inline"
                replace
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={() => navigate("/signup")}
              >
                <FontAwesomeIcon icon="fa-solid fa-right-from-bracket" />
                SignUp
              </button>
            </li> */}
          </ul>
        </>
      );
    }
  };

  const [openedDrawer, setOpenedDrawer] = useState(false);

  // function toggleDrawer() {
  //   setOpenedDrawer(!openedDrawer);
  // }

  function changeNav(event) {
    if (openedDrawer) {
      setOpenedDrawer(false);
    }
  }

  const goBack = () => {
    //swal(window.location.href.includes("ordersuccess"));
    if (window.location.href.includes("ordersuccess")) {
      navigate("/home");
    } else {
      navigate(-1);
    }
  };

  const scrollBarStyle = {
    width: "1px",
  };

  const [selectedLanguage, setSelectedLanguage] = useState("Select a language");

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
    dispatch(LanguageSelector(selectedLanguage));
  };

  return (
    <>
      <nav
        className="navbar navbar-dark fixed-top"
        style={{ background: "rgba(140, 71, 29, 1)" }}
      >
        <div
          className={`px-sm-3 pe-2 ${
            Location?.pathname === "/" ? "" : "ps-3"
          }  d-flex w-100`}
          style={{}}
        >
          {/* ordercancel */}
          {window?.location?.href.includes("ordersuccess") ||
          window?.location?.href.includes("ordercancel") ? (
            <Link className="navbar-brand  " to="/">
              {Location?.pathname === "/" ? (
                ""
              ) : (
                <FontAwesomeIcon icon="fa-solid fa-arrow-left " />
              )}

              <span></span>
            </Link>
          ) : (
            <Link className="navbar-brand  " onClick={() => navigate(-1)}>
              {Location?.pathname === "/" ? (
                ""
              ) : (
                <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
              )}

              <span></span>
            </Link>
          )}
          <div
            className=" me-auto  overflow-x-auto "
            style={{ marginTop: "3px" }}
          >
            <Link className="navbar-brand " to="/">
              <img
                src="./../logo192.png"
                class="img no-scrollbar me-1"
                alt="appLogo"
                style={{
                  objectFit: "contain",
                  height: 30,
                  width: 30,
                  overflow: "hidden",
                }}
              ></img>
              <span> </span>

              <small
                className={`${
                  (Location?.pathname === "/" &&
                    window?.innerWidth <
                      (userRole?.includes(ROLE) ? 350 : 315)) ||
                  (Location?.pathname !== "/" &&
                    window?.innerWidth < (userRole?.includes(ROLE) ? 400 : 350))
                    ? "d-none"
                    : ""
                }`}
              >
                Shree Sarv Shakti
              </small>
            </Link>
          </div>

          {userRole?.includes(ROLE) ? (
            <>
              <CustomSwitch />
              <button
                className="btn text-light border-0 position-relative me-2 me-sm-0 "
                onClick={() => navigate("/orderhistory")}
              >
                <FontAwesomeIcon icon="fa-solid fa-cart-arrow-down" />
              </button>
            </>
          ) : (
            <>
              <button
                className="btn text-light border-0  d-sm-block d-none  position-relative  "
                onClick={() => navigate("/about")}
              >
                About
              </button>
              <button
                to="/addtocart"
                className="btn text-light  me-2 me-sm-0  position-relative  "
                style={{ marginTop: "1px" }}
                // onClick={changeNav}
                onClick={() => navigate("/addtocart")}
              >
                <FontAwesomeIcon
                  icon={["fas", "shopping-cart"]}
                  data-bs-placement="bottom"
                  title="Cart"
                />

                <span
                  class="position-absolute translate-middle badge rounded-pill bg-secondary"
                  style={{ marginTop: "4px" }}
                >
                  {counter == 0 ? "" : counter}
                </span>
              </button>
            </>
          )}
          <button
            // to={"/products"}
            type="button"
            // PrettierPrettier
            // replace
            // onClick={changeNav}
            className="btn text-light d-none d-sm-block"
            style={{ marginTop: "1px" }}
            onClick={() => navigate("/products")}
          >
            <FontAwesomeIcon
              icon={["fas", "search"]}
              data-bs-placement="bottom"
              title="Search"
            />
          </button>
          <div className="  px-0 d-none d-sm-block">
            {/* <button
          to="/login"
          type="button"
          // PrettierPrettier
          // replace
          // onClick={changeNav}
          onClick={() => navigate("/login")}
          className="btn text-light 
                "
        >
          <FontAwesomeIcon
            icon="fa-solid fa-right-from-bracket"
            data-bs-placement="bottom"
            title="Login"
          />
        </button> */}
            {isLogged ? (
              <button
                className="btn text-light "
                onClick={() => navigate("/profile")}
              >
                <FontAwesomeIcon icon="fa-solid fa-user" />
              </button>
            ) : (
              <button
                className="btn text-light  "
                onClick={() => navigate("/login")}
              >
                <FontAwesomeIcon
                  icon="fa-solid fa-right-from-bracket"
                  data-bs-placement="bottom"
                  title="Login"
                />
              </button>
            )}
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasDarkNavbar"
            aria-controls="offcanvasDarkNavbar"
          >
            <span className="fs-6  navbar-toggler-icon" />
          </button>
          <div
            className="offcanvas offcanvas-end text-bg-primary"
            tabIndex={-1}
            id="offcanvasDarkNavbar"
            aria-labelledby="offcanvasDarkNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5
                className="offcanvas-title text-light"
                id="offcanvasDarkNavbarLabel"
              >
                <img
                  src="./../favicon.ico"
                  class="img me-2"
                  alt="appLogo"
                ></img>
                Shree Sarv Shakti
              </h5>

              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              />
            </div>

            <div className="offcanvas-bodyn ">
              <ul className="navbar-nav justify-content-end flex-grow-1 gap-3  px-4">
                <li className="nav-item text-truncate ">
                  <button
                    className=" text-light bg-primary border border-primary "
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    onClick={() => navigate("/orderhistory")}
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-clock-rotate-left"
                      className="me-3"
                    />
                    Booking History
                  </button>
                </li>

                {/* <li className="nav-item text-truncate"> */}
                {/* <button
                  className="nav-button  text-light"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  replace
                  onClick={() => navigate("/tokenbooking")}
                >
                  <FontAwesomeIcon
                    icon="fa-solid fa-list-ol"
                    className="me-3 text-light"
                  />
                  Get Token
                </button> */}
                {/* <button
                  to="/products"
                  className=" text-light bg-primary border border-primary "
                  replace
                  onClick={changeNav}
                >
                  <FontAwesomeIcon
                    icon="fa-solid fa-list-ol"
                    className="me-3 text-light"
                  />
                  Categories
                </button> */}
                {/* </li> */}

                <li className="nav-item">
                  <button
                    // to="/products"
                    className=" text-light bg-primary border border-primary "
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    replace
                    onClick={() => navigate("/products")}
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-list-ol"
                      className="me-3 text-light"
                    />
                    Categories
                  </button>
                </li>
                <li className="nav-item ">
                  <button
                    // to="/contact"
                    className=" text-light bg-primary border border-primary "
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    replace
                    onClick={() => navigate("/contact")}
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-phone"
                      className="me-3"
                    />
                    Contact Us
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    to="/aboutgridcard"
                    className=" text-light bg-primary border border-primary "
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    replace
                    onClick={() => navigate("/about")}
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-address-card"
                      className="me-3"
                    />
                    About Us
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    to="/aboutgridcard"
                    className=" text-light bg-primary border border-primary "
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    replace
                    onClick={() => navigate("/reviews")}
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-message"
                      className="me-3"
                    />
                    Reviews
                  </button>
                </li>
                {/* <li className="nav-item pb-2">
                    <FontAwesomeIcon icon={["fas", "search"]} />
                    <button
                      // to={"/products"}
                      type="button"
                      className="btn text-light me-3  d-lg-inline  "
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                      onClick={() => "/products"}
                    >
                      Search
                    </button>
                  </li> */}
                <li className="nav-item ">
                  <FontAwesomeIcon
                    icon={["fas", "shopping-cart"]}
                    className="ps-2 text-light "
                  />
                  <button
                    to="/addtocart"
                    className="btn text-light  position-relative  d-lg-inline"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    type="button"
                    replace
                    onClick={() => navigate("/addtocart")}
                  >
                    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary">
                      {counter == 0 ? "" : counter}
                    </span>
                    Cart
                  </button>
                </li>
                <li className="nav-item ">
                  <FontAwesomeIcon
                    icon="fa-solid fa-video"
                    flip
                    className=" ps-2 text-light "
                  />
                  <button
                    to="/video"
                    className="btn text-light  position-relative  d-lg-inline "
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    type="button"
                    replace
                    onClick={() => navigate("/videos")}
                  >
                    {" "}
                    Videos
                  </button>
                </li>
                {/* <li className="nav-item pb-2">
                <FontAwesomeIcon
                  icon="fa-solid fa-newspaper"
                  beatFade
                  className="pe-2 text-light "
                />
                <button
                  to="/article"
                  className="btn text-light  position-relative  d-lg-inline"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  type="button"
                  replace
                  onClick={() => navigate("/article")}
                >
                  Article
                </button>
              </li> */}
                <li className="nav-item ">
                  <RenderMenu />
                </li>
              </ul>
              {/* <form className="d-flex mt-3" role="search">
          <input
            className="form-control me-2"
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
          <button className="btn btn-success" type="submit">
            Search
          </button>
        </form> */}
            </div>
          </div>
        </div>
      </nav>

      <div
        className="d-flex fixed-bottom-right gap-sm-4 gap-2 py-1 px-3 no-scrollbar"
        style={{
          backgroundColor: "#f8f9fa",
          borderBottomLeftRadius: "3px",
          overflowX: "auto", // Enables horizontal scrolling
          whiteSpace: "nowrap", // Prevents line breaks
        }}
      >
        <p style={{ margin: 0, paddingLeft: 4 }}>
          <FontAwesomeIcon
            icon="fa-solid fa-phone"
            style={{ color: "lightgray", marginRight: 7 }}
          />
          (+91) 7985082508
        </p>
        <p style={{ margin: 0 }}>
          <a
            href="mailto:shaktishreesarv@gmail.com"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <FontAwesomeIcon
              icon="fa-solid fa-envelope"
              style={{ color: "lightgray", marginRight: 7 }}
            />
            shaktishreesarv@gmail.com
          </a>
        </p>

        <p style={{ margin: 0, paddingRight: 3 }}>
          <a
            href="https://wa.me/917985082508"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <FontAwesomeIcon
              icon="fa-brands fa-square-whatsapp"
              style={{ color: "lightgray", marginRight: 7 }}
            />
            <span>(+91) 7985082508</span>
          </a>
        </p>
      </div>

      <style jsx>{`
        .fixed-bottom-right {
          position: fixed;
          top: 51px;
          right: 0;
          z-index: 1000;
          max-width: 100%; /* Constrains width to viewport */
          overflow-x: auto; /* Enables horizontal scrolling */
        }
        @media (max-width: 576px) {
          /* Small screens */
          .fixed-bottom-right {
            max-width: 100%; /* Keeps within screen width */
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      `}</style>
    </>
  );
};

export default Header;
