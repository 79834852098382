import React, { FormEvent, useEffect, useState } from "react";

import { toast, Toaster } from "sonner";
import moment from "moment";
import {
  ADD_COMMENT,
  BASE_URL,
  DELETE_COMMENT,
  GET_COMMENT_BY_ITEMID,
  GET_LANDING_COMMENT,
  ROLE,
  SESSION_ID,
  USER_ROLE,
} from "../Constant";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";

const getComment = async (itemId) => {
  try {
    const payload = JSON.stringify({
      pubInfo: {
        sessionId:
          localStorage.getItem(SESSION_ID) ??
          "30B16E3C3E1A54E4B4370295940FBB74",
      },
      request: {
        busiParams: itemId ? { itemID: itemId } : {},

        isEncrypt: false,
        transactionId: "897987987989",
      },
    });

    let res = await axios.post(
      BASE_URL + (itemId ? GET_COMMENT_BY_ITEMID : GET_LANDING_COMMENT),
      payload,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          //   Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        },
      }
    );
    // console.log("sksks", res)
    return res?.data?.respData?.respMsg;
  } catch (error) {
    console.log(error, "coming from addtocard");
    return null;
  }
};
const postComment = async (parmss) => {
  try {
    const payload = JSON.stringify({
      pubInfo: {
        sessionId: localStorage.getItem(SESSION_ID),
      },
      request: {
        busiParams: {
          ...parmss,
        },
        isEncrypt: false,
        transactionId: "897987987989",
      },
    });

    let res = await axios.post(BASE_URL + ADD_COMMENT, payload, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
        "Access-Control-Allow-Headers": "Content-Type",
        "Content-Type": "application/json",
        //   Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN)
      },
    });

    return res?.data?.respData?.respMsg;
  } catch (error) {
    // console.error(error, 'coming from addtocard');
    return null;
  }
};
const deleteCommentById = async (ID) => {
  try {
    const payload = JSON.stringify({
      pubInfo: {
        sessionId: localStorage.getItem(SESSION_ID),
      },
      request: {
        busiParams: {
          commentID: ID,
        },
        isEncrypt: false,
        transactionId: "897987987989",
      },
    });

    let res = await axios.post(BASE_URL + DELETE_COMMENT, payload, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
        "Access-Control-Allow-Headers": "Content-Type",
        "Content-Type": "application/json",
        //   Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN)
      },
    });
    toast.success("Removed Successfully");
    return true;
  } catch (error) {
    toast.error("Oops went something wrong");
    // console.error(error, 'coming from addtocard');
    return false;
  }
};

const CustomerReview = ({ itemid }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [comments, setComments] = useState([{}]);
  const editMode = useSelector((state) => state.editMode.editMode);
  let [showEdit, setShowEdit] = useState(false);
  const [activeTab, setActiveTab] = useState("latest");
  const [filteredComments, setFilteredComments] = useState([]);
  const [query, setQuery] = useState({
    name: "",
    message: "",
  });

  const [startCount, setStarCount] = useState(3);

  const handleStars = (index) => {
    setStarCount(index + 1);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (startCount === 0) {
      toast.warning("Please select rating star");
    } else {
      postQuerry();
    }
  };

  const handleChangs = (e) => {
    const { name, value } = e.target;
    setQuery((prev) => ({ ...prev, [name]: value }));
  };

  const getAllComments = async () => {
    try {
      const comments = await getComment(itemid);
      setComments(comments !== null ? comments : []);
    } catch (error) {
      console.log("Error fetching comments:", error);
    }
  };

  const postQuerry = () => {
    try {
      const busiParms = {
        itemID: itemid ? itemid : "",
        personeName: query?.name,
        rating: startCount,
        commentMessage: query?.message,
        date: "" + moment(new Date()).format("YYYY-MM-DD"),
      };

      toast.promise(
        postComment(busiParms).then(() => {
          getAllComments();
          setQuery({
            name: "",
            message: "",
          });
        }),
        {
          loading: "Submitting your review...",
          success:
            "Thank you for your valuable review! We greatly appreciate your feedback.",
          error:
            "An error occurred while submitting your review. Please try again.",
        }
      );
    } catch (error) {
      console.error("Error submitting comments:", error);
      toast.error(
        "An error occurred while submitting your review. Please try again."
      );
    }
  };

  useEffect(() => {
    // Filter comments based on active tab
    if (activeTab === "latest") {
      const latestComments = [...comments].sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
      setFilteredComments(latestComments);
    } else if (activeTab === "top") {
      setFilteredComments(comments);
    }
  }, [activeTab, comments]);

  const deleteComment = (cmntId) => {
    toast.info(
      <div>
        <span className="fw-semibold fs-6">
          Are you sure about to remove this comment
        </span>
        <div
          className="d-flex justify-content-center mt-2"
          style={{ gap: "30px" }}
        >
          <button
            className="btn btn-primary text-white"
            style={{
              height: "30px",
              margin: 0,
              display: "flex",
              fontSize: 12,
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={async () => {
              const isSuccess = await deleteCommentById(cmntId);
              console.log(isSuccess, "after deleted");
              if (isSuccess) getAllComments();
              toast.dismiss();
            }}
          >
            Yes
          </button>
          <button
            className="btn btn-outline-primary"
            style={{
              height: "30px",
              margin: 0,
              fontSize: 11,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => toast.dismiss()}
          >
            No
          </button>
        </div>
      </div>
    );
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    getAllComments();
  }, []);

  return (
    <div className="mb-5 bg-transparent ">
      <Toaster position="top-right" richColors={true} />
      {filteredComments?.length > 0 && (
        <>
          <div className="d-flex mb-4 px-sm-3 px-3" style={{ gap: "30px" }}>
            <button
              className={`btn ${
                activeTab === "latest"
                  ? "btn-primary  text-white"
                  : "btn-outline-primary  text-black"
              }`}
              style={{
                height: "35px",
                margin: 0,
                fontSize: 14,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => handleTabClick("latest")}
            >
              Latest Comments
            </button>
            <button
              className={`btn ${
                activeTab === "top"
                  ? "btn-primary text-white"
                  : "btn-outline-primary text-black"
              } `}
              style={{
                height: "35px",
                margin: 0,
                fontSize: 14,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => handleTabClick("top")}
            >
              Top Comments
            </button>
          </div>
          <div
            className=" d-flex no-scrollbar px-sm-3 px-3 pb-5"
            style={{
              display: "flex",
              gap: "26px",
              overflowX: "auto",
              // animation: 'scrollLeft 20s linear infinite', // Infinite scroll animation
            }}
            onMouseEnter={(e) =>
              (e.currentTarget.style.animationPlayState = "paused")
            } // Pause animation on hover
            onMouseLeave={(e) =>
              (e.currentTarget.style.animationPlayState = "running")
            }
          >
            {filteredComments
              .filter((item) => item?.personeName != null)
              .map((comment, index) => (
                <div key={index} tyle={{ flexGrow: 1, width: "200px" }}>
                  <ReviewCard
                    comment={comment}
                    showEdit={editMode}
                    deleteComment={deleteComment}
                  />
                </div>
              ))}
          </div>
        </>
      )}
      <div className="px-3 px-sm-3">
        <form onSubmit={handleSubmit} className="row g-4">
          <div className="col-md-8    rounded-2">
            <textarea
              name="message"
              value={query.message}
              onChange={handleChangs}
              placeholder={
                itemid
                  ? "Write your experience here about this puja"
                  : "Write your experience here...."
              }
              className=" w-100  border-primary p-2 shadow-sm bg-white  placeholder-secondary rounded-2"
              rows={7}
              maxLength={450}
              required
            />
          </div>
          <div className="col-md-4 border-0 rounded-2">
            <textarea
              type="text"
              name="name"
              value={query.name}
              onChange={handleChangs}
              placeholder="Your good name"
              className="w-100  p-3 shadow-sm  bg-white border-primary placeholder-secondary rounded-2"
              required
              rows={1}
              maxLength={50}
            />
            <div className="d-flex gap-2 mt-3   rounded-2 p-2 align-content-center ">
              <p
                className="text-primary font-weight-bold"
                style={{ margin: 0, marginTop: 2 }}
              >
                Rate: {startCount}/5
              </p>
              {Array.from({ length: 5 }).map((_, index) =>
                index < startCount ? (
                  <img
                    key={index}
                    src={"/god/icons/fillstar.png"}
                    width={21}
                    height={21}
                    alt="Filled star"
                    className="cursor-pointer"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleStars(index)}
                  />
                ) : (
                  <img
                    key={index}
                    src={"/god/icons/star.png"}
                    width={21}
                    height={21}
                    alt="Empty star"
                    className="cursor-pointer"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleStars(index)}
                  />
                )
              )}
            </div>
            <button
              type="submit"
              disabled={isLoading}
              className="btn btn-primary w-100 mt-4 text-white shadow-sm"
            >
              {isLoading ? "Loading..." : "Post"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CustomerReview;

const ReviewCard = ({ comment, showEdit, deleteComment }) => {
  const initials = comment?.personeName
    ?.split(" ")
    .map((name) => name[0])
    .join("");

  return (
    <div
      style={{ height: "100%", width: "300px" }}
      className="card   shadow-sm border border-primary rounded-xl p-3"
    >
      <div className="d-flex gap-3 align-items-center">
        <div className="avatar">
          <div
            className="avatar-placeholder bg-secondary text-white d-flex justify-content-center align-items-center rounded-circle"
            style={{ width: "45px", height: "45px" }}
          >
            {initials}
          </div>
        </div>
        <div>
          <h5 className="font-weight-bold">{comment?.personeName}</h5>
          {showEdit && (
            <FontAwesomeIcon
              icon="fa-solid fa-trash "
              className="me-auto text-danger"
              onClick={() => deleteComment(comment?.commentID)}
              style={{
                cursor: "pointer",
                position: "absolute",
                bottom: 20,
                right: 20,
              }}
            />
          )}
          <div className="d-flex gap-1">
            {Array.from({ length: 5 }).map((_, index) =>
              index < comment?.rating ? (
                <img
                  key={index}
                  src={"/god/icons/fillstar.png"}
                  width={19}
                  height={19}
                  alt="Filled star"
                />
              ) : (
                <img
                  key={index}
                  src={"/god/icons/star.png"}
                  width={19}
                  height={19}
                  alt="Empty star"
                />
              )
            )}
          </div>
        </div>
      </div>
      <p className="text-muted mt-2">{comment?.commentMessage}</p>
      <p className="text-right text-muted mb-0 mt-auto">
        {comment?.date && moment(comment?.date).fromNow()}
      </p>
    </div>
  );
};
