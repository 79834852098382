import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import ForgotPasswordService from "./ForgotPasswordService";

import password from "./password.css";
import swal from "sweetalert";

import {
  SESSION_ID,
  BASE_URL,
  DEBUG_LOG_ENABLED,
  ACCESS_TOKEN,
  AddPassword,
  ERROR_MSG,
} from "../Constant";
import axios from "axios";
import { toast, Toaster } from "sonner";
import SignUpService from "../signup/SignUpService";
import { useLocation, useNavigate } from "react-router-dom";
export default function ForgotPassword(props) {
  const location = useLocation();
  const email = location.state?.email || "";
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [customer, setCustomer] = useState({
    name: "",
    emailId: email,
    mobileNo: "",
    password: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    setShow(true);
  }, []);

  const handleClick = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      toast.success("Verified");
      setShow(false);
    }, 1000);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setCustomer({ ...customer, [e.target.name]: value });
  };

  const saveCustomer = (e) => {
    e.preventDefault();
    if (
      customer.name === "" ||
      customer.password === "" ||
      customer.emailId === "" ||
      customer.mobileNo === ""
    ) {
      toast.warning(" Please enter all fields ");
    } else if (confirmPassword !== customer.password) {
      toast.warning(" Password not matched ");
    } else {
      setIsLoading(true);
      const customerData = JSON.stringify({
        pubInfo: {
          sessionId: "3D3CFD149568C3AD31F4D3A9A9010C84",
        },
        request: {
          busiParams: {
            name: customer.name,
            emailId: customer.emailId,
            mobileNo: customer.mobileNo,
            password: customer.password,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      SignUpService.saveCustomer(customerData)
        .then((response) => {
          setIsLoading(false);
          toast.success("Password changed");
          setTimeout(() => {
            navigate("/login");
          }, 1000);
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(ERROR_MSG);
        });
    }
  };
  return (
    <div
      className="justify-content-center align-items-center container  py-5  mx-auto mt-5 "
      style={{ minHeight: "400px", width: "100%" }}
    >
      <Toaster position="top-right" richColors={true} />

      <div className=" shadow-sm rounded-2 card p-2 justify-content-center align-items-center py-4 px-3 mt-3">
        {show ? (
          <div className="col-md-6 col-lg-4 mb-2">
            <p className="text-muted fw-bold fs-4">Forgot Password ?</p>
            <div className="form-group  mt-3 text-muted">
              <p className="text-muted fw-bold fs-6">
                You can proceed further to reset your password
              </p>
            </div>
            <div className="form-group mt-3 fs-6">
              <label>Email</label>
              <input
                type="email"
                name="emailId"
                value={customer.emailId}
                onChange={(e) => handleChange(e)}
                className="form-control mt-1"
                placeholder="Enter your email"
              />
            </div>
            <div className="d-grid gap-2 mt-4">
              <button
                onClick={handleClick}
                type="button"
                class="btn btn-primary text-white"
              >
                {loading ? (
                  <div
                    className="spinner-border text-white "
                    style={{ height: 14, width: 14, fontSize: "10px" }}
                    role="status"
                  ></div>
                ) : (
                  "Verify it's you"
                )}
              </button>
            </div>
          </div>
        ) : (
          <div className="col-11 col-md-6 col-lg-4  mb-2 ">
            <p className="text-muted fw-bold fs-4">Reset here</p>
            <div className="form-group mt-3 ">
              <label>Full Name</label>
              <input
                type="text"
                name="name"
                value={customer.name}
                onChange={(e) => handleChange(e)}
                className="form-control mt-1"
                placeholder="Enter your name"
              />
            </div>
            <div className="form-group mt-3 ">
              <label>Mobile Number</label>
              <input
                type="text"
                name="mobileNo"
                value={customer.mobileNo}
                onChange={(e) => handleChange(e)}
                className="form-control mt-1"
                placeholder="Enter your number"
              />
            </div>
            <div className="form-group mt-3">
              <label>Email</label>
              <input
                type="email"
                name="emailId"
                value={customer.emailId}
                onChange={(e) => handleChange(e)}
                className="form-control mt-1"
                placeholder="Enter your email"
                disabled={true}
              />
            </div>
            <div className="form-group mt-3">
              <label>Password</label>
              <input
                type="password"
                name="password"
                value={customer.password}
                onChange={(e) => handleChange(e)}
                className="form-control mt-1"
                placeholder="Password"
              />
            </div>
            <div className="form-group mt-3">
              <label>Confirm Password</label>
              <input
                type="password"
                name="confirm password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="form-control mt-1"
                placeholder="Re enter the password"
              />
            </div>
            <div className="d-grid gap-2 mt-4 ">
              <button
                onClick={saveCustomer}
                type="submit"
                className="btn btn-primary text-white "
              >
                {isLoading ? (
                  <div
                    class="spinner-border text-white "
                    role="status"
                    style={{
                      fontSize: "12px",
                      width: "18px",
                      height: "18px",
                    }}
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
