import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import React from "react";
import CheckoutStepper from "../components/CheckoutStepper";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { increment } from "../actions";
import swal from "sweetalert";

import {
  CUSTOMER_ID,
  SESSION_ID,
  BASE_URL,
  SetOrders,
  ACCESS_TOKEN,
  DEBUG_LOG_ENABLED,
} from "../Constant";
import axios from "axios";
import moment from "moment";
import { useLoaderData, useNavigate } from "react-router-dom";

function OrderSuccess() {
  const [cartList, setcartList] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [newlocation, setnewlocation] = useState(" ");

  const addressData = localStorage.getItem("addressData");
  const addressList = JSON.parse(addressData);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   const location = window.location.href;

  //   var urlspit = location.split("token=").pop();
  //   var urlBrek = urlspit.split("&");
  //   setnewlocation(urlBrek[0]);
  //   // swal(urlBrek[0]);

  //  // completepayPalPayment(urlBrek[0]);
  //   dispatch(increment(0));
  //   const currency = localStorage.getItem("currency");
  //   if (currency === "USD") {
  //     localStorage.setItem("price", "");
  //     localStorage.setItem("currency", "");
  //     localStorage.setItem("cartListData", JSON.stringify(cartList));
  //     localStorage.setItem("clientId", "");
  //     localStorage.setItem("razorpayKeyId", "");
  //   } else {
  //     const orderId = localStorage.getItem("UpiOderId");
  //     // if (orderId !== "") {
  //     //    setOrder();
  //     // }
  //   }
  // }, []);
  return (
    <div className="container  py-5 px-xl-5 mx-auto mt-5 maxWidth 900px">
      <div className="col-md-12">
        <CheckoutStepper step={3} />
      </div>
      <div className="row h-100">
        <div className="col-md-12 h-100">
          <div className="card border-1 shadow-sm h-100 bg-transparent  ">
            <div className="card-body mt-5">
              <div className="d-flex justify-content-center mb-3">
                <FontAwesomeIcon
                  icon={("fas", "check-circle")}
                  size="5x"
                  className="text-success"
                />
              </div>
              <h3 className="text-center">
                Your order has been dispatch Successfully{" "}
              </h3>
              <h6 className="text-center h-5">
                We&apos;ve received your order. You can track order status in
                your order history and your payment reference
                {/* {newlocation ? newlocation : localStorage.getItem("UpiOderId")} */}
                <span className="fw-bold">
                  {" "}
                  "{localStorage.getItem("orderID")}"
                </span>
                <p
                  style={{
                    textDecoration: "underline", // Adds underline to the text
                    cursor: "pointer",
                  }}
                  className="text-primary"
                  onClick={() => navigate("/orderhistory", { replace: true })}
                >
                  Check Order history
                </p>
              </h6>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
}

export default OrderSuccess;
