import { Link } from "react-router-dom";
import React, { useState, useContext } from "react";
import CartService from "../services/CartService";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Ratings from "react-ratings-declarative";
import swal from "sweetalert";
import flower from "../images/art/Flower.png";
import { toast, Toaster } from "sonner";
import {
  ACCESS_TOKEN,
  CUSTOMER_ID,
  SESSION_ID,
  BASE_URL,
  ADD_TO_CART,
  MYPRODUCT,
  DEBUG_LOG_ENABLED,
  BASE_URL_IMG,
  CART_COUNT,
} from "../Constant";

import { useSelector, useDispatch } from "react-redux";
import { increment } from "../actions";
import axios from "axios";
import moment from "moment";

const iconPath =
  "M18.571 7.221c0 0.201-0.145 0.391-0.29 0.536l-4.051 3.951 0.96 5.58c0.011 0.078 0.011 0.145 0.011 0.223 0 0.29-0.134 0.558-0.458 0.558-0.156 0-0.313-0.056-0.446-0.134l-5.011-2.634-5.011 2.634c-0.145 0.078-0.29 0.134-0.446 0.134-0.324 0-0.469-0.268-0.469-0.558 0-0.078 0.011-0.145 0.022-0.223l0.96-5.58-4.063-3.951c-0.134-0.145-0.279-0.335-0.279-0.536 0-0.335 0.346-0.469 0.625-0.513l5.603-0.815 2.511-5.078c0.1-0.212 0.29-0.458 0.547-0.458s0.446 0.246 0.547 0.458l2.511 5.078 5.603 0.815c0.268 0.045 0.625 0.179 0.625 0.513z";
function changeRating(newRating) {}

function FeatureProduct({ product }) {
  const dispatch = useDispatch();
  //const {state, dispatch} = useContext(UserContext);
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const isLogged = useSelector((state) => state.isLogged);
  const language = useSelector((state) => state.Language);

  //  const[ panditcount , setpanditcount]=useState();
  //   const [priceInDollar ,setpriceInDollar]=useState();
  //   const[ newitemPrice ,setnewitemPrice]=useState();

  const itemPrice = 0;

  const addToMycart = (newitemPrice, priceInDollar) => {
    // e.preventDefault();
    const id = localStorage.getItem(CUSTOMER_ID);
    const token = localStorage.getItem(ACCESS_TOKEN);
    if (DEBUG_LOG_ENABLED) {
      console.log("CartService : id:" + id + "  token:" + token);
    }
    if (isLogged && id != null && id != "" && token != "") {
      addToCart(newitemPrice, priceInDollar);
    } else {
      navigate("/login");
    }
  };
  const addToCart = async (newitemPrice, priceInDollar) => {
    try {
      const itemData = JSON?.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            id: 0,
            customerId: localStorage.getItem(CUSTOMER_ID),
            itemId: product.itemId,
            date: product?.headerColor
              ? moment(product.headerColor)
                  .set({ hour: 9, minute: 0 })
                  .format("YYYY-MM-DDTHH:mm") // Format to match input
              : moment().format("YYYY-MM-DDTHH:mm"),
            price: newitemPrice,
            panditCount: priceInDollar,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      if (DEBUG_LOG_ENABLED) {
        console.log("CartService : " + itemData);
      }

      // console.log("itemData, ",itemData)

      //const response = await CartService.addToCart(itemData);
      let response = await axios.post(BASE_URL + ADD_TO_CART, itemData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });

      dispatch(increment(response.data.respData.respMsg.cartCount));
      localStorage.setItem(
        CART_COUNT,
        response.data.respData.respMsg.cartCount
      );
      swal(response.data.respData.message);
      // toast.success()
    } catch (err) {
      if (err.response.status == 401) {
        localStorage.setItem(CUSTOMER_ID, "");
        navigate("/login");
      } else {
        swal("data : " + err);
        if (DEBUG_LOG_ENABLED) {
          console.log(err);
        }
      }
    }
  };
  const handleAddressIdchang = (e, item) => {
    {
      JSON?.parse(product?.description)?.map((item, index) => {
        if (itemPrice === index) {
          addToMycart(item.price, item.priceInDoller);
        }
      });
    }

    // const value = e.target.value;

    // const price =    e.target.item.price;
    // const priceInDollar = e.target.item.priceInDoller;
    // const panditcount = e.target.item.days;

    // console.log(item);
    // setpanditcount(panditcount);
    // setpriceInDollar(priceInDollar);
    // setnewitemPrice(price);
  };
  return (
    // <div className="card h-100 border-0 shadow-sm">

    <div
      className="  bg-transparent   mb-4 bg-body  "
      style={{
        minWidth: 120,
        minHeight: 390,
        width: 276,
        // marginLeft: "15px",
        borderRadius: "15px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
      }}
    >
      {/* <Toaster position="top-right" richColors={true} /> */}
      {/* <div>
        <img
          src={flower}
          alt="..."
          className="position-absolute rounded-3 w-100 h-100"
          style={{ objectFit: "cover" }}
        />
      </div> */}
      <div className=" ratio-1x1 ">
        <img
          onClick={() => {
            // First, store the product in localStorage
            localStorage.setItem("myProduct", JSON?.stringify(product));

            // Then navigate to the product detail page
            navigate("/productdetail/" + product.itemId);
          }}
          className="card-img-top bg-dark cover"
          style={{
            borderTopRightRadius: "15px",
            borderTopLeftRadius: "15px",
            cursor: "pointer",
            boxShadow: "1px 0px 0px rgba(0, 0, 0, 0.2)",
            objectFit: "cover",
          }}
          height="218"
          width="276"
          alt=""
          src={BASE_URL_IMG + product.iconURL}
        />
        {/* </Link> */}
      </div>

      <div className="card-body px-2">
        <h5 className="card-title  text-dark text-truncate mt-3">
          {product.title}
        </h5>

        {/* <div className="card-title text-center text-dark text-truncate">
            <Ratings
              rating={4.5}
              widgetRatedColors="rgb(253, 204, 13)"
              // changeRating={changeRating}
              widgetSpacings="2px"
            >
              {Array.from({ length: 5 }, (_, i) => {
                return (
                  <Ratings.Widget
                    key={i}
                    widgetDimension="20px"
                    svgIconViewBox="0 0 19 20"
                    svgIconPath={iconPath}
                    widgetHoverColor="rgb(253, 204, 13)"
                  />
                );
              })}
            </Ratings>
          </div> */}

        {/* <p className="card-text text-center text-muted">
            {"₹" + product.price + "/- " + "" + "$" + product.priceInDollar}
          </p> */}
        <div>
          {" "}
          {product?.description ? (
            <div class="">
              {JSON?.parse(product?.description)?.map((item, index) => (
                <div class="col mt-2">
                  {itemPrice === index ? (
                    <div class="">
                      <div class=" ">
                        <div class="">
                          <div class="">
                            <div class="">
                              {/* <input
                                  // class="form-check-input"
                                  // type="radio"
                                  // name="flexRadioDefault"
                                  checked
                                  id={item?.price}
                                  value={item}
                                  onChange={(e) =>
                                    handleAddressIdchang(e, item)
                                  }
                                ></input> */}
                            </div>
                            <div
                              class=""
                              // style={{ width: "51px", height: "22px" }}
                            >
                              {/* <h6>
                                {"₹" +
                                  item?.price +
                                  // language+
                                  "/- " +
                                  "" +
                                  "$" +
                                  item?.priceInDoller}
                              </h6> */}
                              <p class="card-title">
                                {item?.days} <br></br>
                                {item?.priests}
                                <br></br>
                                {/* {item.chants} */}
                              </p>
                            </div>
                          </div>

                          {/* <p class="card-text">{parse(`${item?.viewMoreTitle}`)}</p> */}
                          <p class="card-text">
                            {/* {parse(`${newDescription.map((item)=>{   <p> {item.priceInDoller}</p>})}`)} */}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </div>
          ) : (
            ""
          )}{" "}
        </div>

        <div className=" gap-2 mt-3">
          {product?.description && (
            <div>
              {JSON?.parse(product.description)?.map((item, index) => (
                <div
                  key={index}
                  className="d-flex align-items-center justify-content-between "
                >
                  {itemPrice === index && (
                    <h6 className="text-dark me-1" style={{ margin: 0 }}>
                      ₹{item.price} / ${item.priceInDoller}
                    </h6>
                  )}
                  {index === 0 && (
                    // Render the button only once, or conditionally as needed
                    <button
                      className="btn btn-primary text-white "
                      style={{
                        height: "45px",
                        width: "122px",
                        borderRadius: "14px",
                      }}
                      onClick={handleAddressIdchang}
                      type="submit"
                    >
                      <FontAwesomeIcon icon={["fas", "cart-plus"]} /> Add
                    </button>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
    // </div>
  );
}

export default FeatureProduct;
