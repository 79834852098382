import { Link, useNavigation } from "react-router-dom";
import { BASE_URL_IMG, MYPRODUCT } from "../Constant";
import moment from "moment";

function ReviewCartItem({ product, currency }) {
  // console.log("dd", product);
  return (
    <div className="d-flex">
      <div className="flex-shink-0">
        <Link
          to={"/productdetail/" + product?.itemId}
          onClick={() =>
            // localStorage.setItem(MYPRODUCT,JSON.stringify(item))
            localStorage.removeItem(MYPRODUCT)
          }
        >
          <img
            className="rounded"
            src={BASE_URL_IMG + product.iconURL}
            width={80}
            height={80}
            alt="Product image."
            style={{ objectFit: "cover", cursor: "pointer" }}
          />
        </Link>
      </div>
      <div className="ms-3 h-100">
        <div className="vstack">
          <a className="text-dark text-decoration-none">
            {product?.title?.substring(0, 50)} ...{" "}
          </a>

          {currency !== "USD" ? (
            <h6 className="my-2">{"₹" + product?.price}</h6>
          ) : (
            <h6 className="my-2">{"$" + product?.panditCount}</h6>
          )}
          <span className="">
            {" "}
            <span className="text-muted"> Puja Date</span> :{" "}
            {moment(product?.date).format("DD MMM YYYY dddd hh:mm a")}
          </span>
        </div>
      </div>
    </div>
  );
}

export default ReviewCartItem;
