import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import FeatureProduct from "../landing/FeatureProduct";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import RemoveCartItemService from "./RemoveCartItemService";
import { increment } from "../actions";
import swal from "sweetalert";

import moment, { duration } from "moment";
import {
  SESSION_ID,
  CUSTOMER_ID,
  GET_CART_ITEM,
  RemoveFromCart,
  ACCESS_TOKEN,
  BASE_URL,
  ADD_TO_CART,
  CURRENCY,
  PRICE,
  CARTLISTDATA,
  MYPRODUCT,
  DEBUG_LOG_ENABLED,
  BASE_URL_IMG,
  CART_COUNT,
  PRIMARY_LIGHT,
  ERROR_MSG,
} from "../Constant";
import axios from "axios";
import { toast, Toaster } from "sonner";
import { addToCartBg } from "../utils/icons";

function AddToCart() {
  const dispatch = useDispatch();
  const [date, setDate] = useState("");

  var totalCartPrice = 0;
  var totalCartPriceDollar = 0;
  let [Loader, setLoader] = useState(false);
  const [price, setPrice] = useState(0);
  const [currency, setCurrency] = useState("USD");

  const handleChange = (e) => {
    const value = e.target.value;
    var dataArray = value.split(" ");
    if (dataArray[0] === "₹") {
      setCurrency("INR");
    } else if (dataArray[0] === "$") {
      setCurrency("USD");
    }

    setPrice(parseFloat(dataArray[1]));
  };

  const navigate = useNavigate();
  //const responseJson = navigate.getParam("myJSON");

  const [catItemList, setCartItemList] = useState([]);

  const getCartLists = async () => {
    try {
      const cartListData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            customerId: localStorage.getItem(CUSTOMER_ID),
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      //const response = await GetCartItemService.create(cartListData);
      let response = await axios.post(BASE_URL + GET_CART_ITEM, cartListData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });
      // console.log(" cart ", response?.data?.respData?.respMsg?.cartList);
      setCartItemList(response?.data?.respData?.respMsg?.cartList);
      if (DEBUG_LOG_ENABLED) {
        console.log("catItemList : " + catItemList);
      }
      // swal("data : " + catItemList);
      setLoader(false);
    } catch (err) {
      if (err.response.status == 401 || err.response.status == 404) {
        navigate("/login");
      } else {
        toast.error(ERROR_MSG);
        if (DEBUG_LOG_ENABLED) {
          console.log(err);
        }
        setLoader(false);
      }
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    const customerId = localStorage.getItem(CUSTOMER_ID);
    if (customerId === undefined || customerId === null || customerId === "") {
      localStorage.setItem(CUSTOMER_ID, "");
      navigate("/login");
    } else {
      setLoader(true);
      getCartLists();
    }
  }, []);

  const removeItemFromCart = async (item) => {
    try {
      const removeCartItem = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            customerId: localStorage.getItem(CUSTOMER_ID),
            itemId: item.itemId,
            price: item.price,
            panditCount: item.panditCount,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      // const response = await RemoveCartItemService.create(removeCartItem);
      let response = await axios.post(
        BASE_URL + RemoveFromCart,
        removeCartItem,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        }
      );

      dispatch(increment(response?.data?.respData?.respMsg?.cartCount));
      localStorage.setItem(
        CART_COUNT,
        response?.data?.respData?.respMsg?.cartCount
      );
      toast.success("Removed Successfully");
      getCartLists();
    } catch (err) {
      if (err.response.status == 401) {
        navigate("/login");
      } else {
        toast.error(ERROR_MSG);
        if (DEBUG_LOG_ENABLED) {
          console.log(err);
        }
      }
    }
  };

  const removeItem = (item) => {
    removeItemFromCart(item);
  };

  const handleDateChange = (e, item) => {
    e.preventDefault();
    const value = e.target.value;
    // console.log("sss", value);
    // const date = moment(Date(value)).format("YYYY-MM-DD HH:mm");
    addToCart(value, item);
    e.preventDefault();
  };
  const addToCart = async (value, item) => {
    try {
      const itemData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            id: 0,
            customerId: localStorage.getItem(CUSTOMER_ID),
            itemId: item.itemId,
            price: item.price,
            date: value,
            panditCount: item.panditCount,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      if (DEBUG_LOG_ENABLED) {
        console.log("CartService : " + itemData);
      }

      // const response = await CartService.addToCart(itemData);

      let response = await axios.post(BASE_URL + ADD_TO_CART, itemData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });
      dispatch(increment(response?.data?.respData?.respMsg?.cartCount));

      localStorage.setItem(
        CART_COUNT,
        response?.data?.respData?.respMsg?.cartCount
      );
      // swal(response?.data?.respData?.message);
      getCartLists();
    } catch (err) {
      if (err.response.status == 401) {
        localStorage.setItem(CUSTOMER_ID, "");
        navigate("/login");
      } else {
        toast.error(ERROR_MSG);
        if (DEBUG_LOG_ENABLED) {
          console.log(err);
        }
      }
    }
  };

  const shippingDetail = (e) => {
    localStorage.setItem(CARTLISTDATA, JSON.stringify(catItemList));
    localStorage.setItem(PRICE, price);
    localStorage.setItem(CURRENCY, currency);
    if (price === 0) {
      toast.warning("Please select the given amount to pay");
    } else if ((catItemList[0].date = "")) {
      toast.warning("Please select the given date of pujan to processed");
    } else {
      navigate("/addaddress");
    }
  };

  const parsePrice = (price) => {
    if (typeof price === "string" && price?.includes(",")) {
      price = price.replace(/,/g, ""); // Remove commas
    }
    return parseFloat(price); // Convert to a float
  };

  return (
    <div
      style={{
        backgroundImage: `url(${addToCartBg})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: window?.innerWidth > 768 ? "700px" : "500px",
      }}
      className=" mt-5 py-5 px-2 px-sm-3 px-md-4 px-xl-5"
    >
      <Toaster position="top-right" richColors={true} />
      <div className="d-lg-flex justify-content-between ">
        <div
          className=" col-lg-8 no-scrollbar"
          style={{
            maxHeight: "100vh",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          {catItemList?.length == 0 && Loader ? (
            <div className="placeholder-glow mt-3 ">
              <div
                className="ratio "
                style={{ "--bs-aspect-ratio": "90%", maxHeight: "200px" }}
              >
                <img
                  className="placeholder disabled border-0 w-100 h-100 rounded-2"
                  alt=""
                  style={{ backgroundColor: PRIMARY_LIGHT }}
                />
              </div>
              <div
                className="ratio mt-4"
                style={{ "--bs-aspect-ratio": "90%", maxHeight: "200px" }}
              >
                <img
                  className="placeholder disabled border-0 w-100 h-100 rounded-2"
                  alt=""
                  style={{ backgroundColor: PRIMARY_LIGHT }}
                />
              </div>
            </div>
          ) : catItemList?.length === 0 ? (
            <div class="row d-flex justify-content-center px-4 my-5 align-items-center h-100 h4">
              There is no puja for now , please add puja.
            </div>
          ) : (
            <div className="d-flex flex-column h-100">
              <ScrollToTopOnMount />
              <p>
                <span class="h4 ps-2 "> Cart Items </span>
              </p>
              {catItemList?.map((item, index) => {
                //panditCount  const dollarResult = parseFloat(item.priceInDollar);
                // const dollarResult = parseFloat(item.panditCount);
                // const result = parseFloat(item.price);
                // totalCartPrice += result;
                // totalCartPriceDollar += dollarResult;
                const dollarResult = parsePrice(item.panditCount); // Parse panditCount
                const result = parsePrice(item.price); // Parse price
                totalCartPrice += result;
                totalCartPriceDollar += dollarResult;

                return (
                  <div>
                    <section>
                      <div class="px-1 h-100">
                        <div class="row d-flex justify-content-center align-items-center h-100">
                          <div class="col">
                            <div class="card mb-4 bg-transparent ">
                              <div class=" p-2 shadow-sm rounded-3 bg-light">
                                <div class="row ">
                                  <div class="col-md-2 d-flex  justify-content-between ">
                                    <Link
                                      to={"/productdetail/" + item.itemId}
                                      onClick={() =>
                                        // localStorage.setItem(MYPRODUCT,JSON.stringify(item))
                                        localStorage.removeItem(MYPRODUCT)
                                      }
                                    >
                                      <img
                                        className="fluid rounded-3"
                                        width="120px"
                                        height="120px"
                                        src={BASE_URL_IMG + item.iconURL}
                                        alt="iconURL"
                                      />
                                    </Link>
                                    <button
                                      onClick={() => removeItem(item)}
                                      type="submit"
                                      style={{ backgroundColor: PRIMARY_LIGHT }}
                                      className="btn  d-block d-md-none small d-flex text-black mb-auto"
                                    >
                                      <FontAwesomeIcon icon="fa-solid fa-xmark" />
                                    </button>
                                  </div>
                                  <div className="col-md-10  px-sm-4 d-flex flex-column justify-content-between  card-title text-left text-dark text-truncate">
                                    <div className="d-flex gap-2 justify-content-between">
                                      <p
                                        className="text-black pt-2 pt-md-0  fw-medium"
                                        style={{
                                          margin: 0,
                                          fontSize: "20px",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        {item.title}
                                      </p>
                                      <button
                                        onClick={() => removeItem(item)}
                                        type="submit"
                                        style={{
                                          backgroundColor: PRIMARY_LIGHT,
                                        }}
                                        className="btn  d-none d-md-block small d-flex text-black mb-auto"
                                      >
                                        <FontAwesomeIcon icon="fa-solid fa-xmark" />
                                      </button>
                                    </div>
                                    <div className="d-flex text-black">
                                      {item?.price && "₹ " + item?.price + " |"}{" "}
                                      {item?.panditCount &&
                                        " $ " + item?.panditCount}
                                    </div>
                                    <div
                                      style={{
                                        flexWrap: "wrap",
                                        display: "flex",
                                        gap: "4px",
                                      }}
                                    >
                                      <label
                                        for="birthdaytime"
                                        className="pe-sm-3 pe-2"
                                      >
                                        Pujan Date{" "}
                                      </label>
                                      <input
                                        type="datetime-local"
                                        id="birthdaytime"
                                        name="birthdaytime"
                                        className="px-2 rounded-3"
                                        value={item?.date}
                                        onChange={(e) =>
                                          handleDateChange(e, item)
                                        }
                                        style={{ borderColor: PRIMARY_LIGHT }}
                                        step="60"
                                      ></input>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        {catItemList?.length > 0 && (
          <div
            className="col-lg-3 mt-lg-5  d-flex shadow-sm rounded-3 border bg-light justify-content-center ms-0"
            style={{
              maxHeight: "200px",
            }}
          >
            <div class=" align-content-center py-2">
              <p>
                <input
                  class="form-check-input"
                  value={"₹" + " " + totalCartPrice}
                  onChange={(e) => handleChange(e)}
                  style={{ cursor: "pointer" }}
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                />
                <span class="h5"> </span>
                <span class="h5">Order Total : </span>
                <span class="h5">{"₹" + " " + totalCartPrice}</span>
              </p>
              <p>
                <input
                  class="form-check-input"
                  value={"$" + " " + totalCartPriceDollar}
                  onChange={(e) => handleChange(e)}
                  type="radio"
                  name="flexRadioDefault"
                  style={{ cursor: "pointer" }}
                  id="flexRadioDefault1"
                />
                <span class="h5"> </span>
                <span class="h5">Order Total : </span>
                <span class="h5">{"$" + " " + totalCartPriceDollar}</span>
              </p>
              <button
                onClick={(e) => shippingDetail(e)}
                class="btn btn-primary btn-md text-truncate px-5 text-white"
              >
                Proceed to buy
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AddToCart;
