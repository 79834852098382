import Template from "./components/Template";
import ProductDetail from "./products/detail/ProductDetail";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "./landing/Landing";
import ProductList from "./products/ProductList";
import AddToCart from "./addtocart/AddToCart";
import About from "./about/AboutUs";
import Contact from "./contact/Contact";
import Error from "./components/Error";
import Login from "./login/Login";
import SignUp from "./signup/SignUp";
import TransKey from "./services/TransKey";
// import GetCategoryService from "./services/GetCategoryService";
import { useState, useEffect, createContext } from "react";
import ForgotPassword from "./forgotpassword/ForgotPassword";
import UpDatePassword from "./updatepassword/UpDatePassword";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ReactGA from "react-ga4";
import {
  ACCESS_TOKEN,
  CART_COUNT,
  Custome_scroll,
  CUSTOMER_ID,
  GOOGLE_ANALYTICS_TRACKING_ID,
} from "./Constant";
import YouTubecard from "./landing/YouTubecard";
import "./index.css";
import {
  SESSION_ID,
  // ACCESS_TOKEN,
  // CUSTOMER_ID,
  ABOUT_US_VIEW,
  DEBUG_LOG_ENABLED,
  GetCategoryLists,
  BASE_URL,
} from "./Constant";
import Profile from "./profile/Profile";
import Logout from "./logout/Logout";
import AddCat from "./addcategory/AddCat";
import CatItem from "./addcategoryitem/CatItem";
import OrderReview from "./oder_review/OrderReview";
import AddAddress from "./newaddress/AddAddress";
import OrderHistory from "./order_history/OrderHistory";
import OrderHistoryDetail from "./order_history/OrderHistoryDetail";
import OrderSuccess from "./oder_review/OrderSuccess";
import Axios from "axios";
import { Navigate } from "react-router-dom";
import Otp from "./login/Otp";
import { HashRouter } from "react-router-dom";
import TermsAndConditions from "./components/TermsAndConditions";
import Qrpay from "./oder_review/Qrpay";
import Videos from "./landing/Allvideos";
import OrderCancel from "./oder_review/OrderCancel";
import Paypal from "./oder_review/Paypal";
import swal from "sweetalert";
import ItemsViewMore from "./Itemviewmore/CatItemsViewMore";
import Headline from "./landing/ Headline";
import Phonpe from "./oder_review/Phonpe";
import Placeholder from "./ placeholder/Placeholder";
import RefundPolicy from "./components/RefundPolicy";
import Privacypolicy from "./components/Privacypolicy";
import Reviews from "./components/Reviews";
import { handleLoggin, increment } from "./actions";
import { useDispatch } from "react-redux";

// import { useEffect } from "react";
//1: contextAPI
export const UserContext = createContext();

const Routing = ({ listData }) => {
  return (
    <Routes>
      <Route path="/" element={<Landing listData={listData} />} />
      <Route path="/products" element={<ProductList />} />
      <Route path="/productdetail/:id" element={<ProductDetail />} />
      <Route path="/about" element={<About />} />
      <Route path="/reviews" element={<Reviews />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/addcat" element={<AddCat />} />
      <Route path="/catitem" element={<CatItem />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/upDatepassword" element={<UpDatePassword />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/addtocart" element={<AddToCart />} />
      <Route path="/addcatitem" element={<CatItem />} />
      <Route path="/orderreview" element={<OrderReview />} />
      <Route path="/addaddress" element={<AddAddress />} />
      <Route path="/orderhistory" element={<OrderHistory />} />
      <Route path="/orderhistorydetail" element={<OrderHistoryDetail />} />
      <Route path="/ordersuccess" element={<OrderSuccess />} />
      <Route path="/termsandconditions" element={<TermsAndConditions />} />
      <Route path="/otp" element={<Otp />} />
      <Route path="/youtubecard" element={<YouTubecard />} />
      <Route path="/qrpay" element={<Qrpay />} />
      <Route path="/videos" element={<Videos />} />
      <Route path="/ordercancel" element={<OrderCancel />} />
      <Route path="/paypal" element={<Paypal />} />
      <Route path="/itemsviewmore" element={<ItemsViewMore />} />
      <Route path="/headline" element={<Headline />} />
      <Route path="*" element={<Navigate to="/" />} />
      <Route path="/Phonpe" element={<Phonpe />} />
      <Route path="refundPolicy" element={<RefundPolicy />} />
      <Route path="Privacypolicy" element={<Privacypolicy />} />

      {/* <Route path="*" element={<Error />} /> */}
    </Routes>
  );
};

export default function App() {
  ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID);
  const [comments, setComments] = useState([]);
  const [data, setData] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      const localData = localStorage.getItem("cat_ascending_data");
      const accessToken = localStorage.getItem(ACCESS_TOKEN);
      const cartCount = localStorage.getItem(CART_COUNT);

      if (localData) {
        const parsedData = JSON?.parse(localData);
        setComments(parsedData);
      }
      if (accessToken) {
        dispatch(handleLoggin(true));
      }
      if (cartCount) {
        dispatch(increment(cartCount));
      }
    } catch (e) {
      console.log(e, "err from main");
    }

    getTransKeyData();
    SendAnalytics();
  }, []);

  const SendAnalytics = () => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
    });
  };

  const getTransKeyData = async () => {
    try {
      const transKeyData = JSON.stringify({
        pubInfo: {
          sessionId: "",
        },
        request: {
          busiParams: {
            key: "",
            type: 0,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      const response = await TransKey.create(transKeyData);
      // console.log("data : " + response.data.respData.respMsg.sessionId);
      setData(response.data.respData.respMsg);
      localStorage.setItem(
        SESSION_ID,
        response.data.respData.respMsg.sessionId
      );
      fetchComments(response.data.respData.respMsg.sessionId);
      if (DEBUG_LOG_ENABLED) {
        console.log(data);
      }
    } catch (err) {
      swal("data : " + err);
      if (DEBUG_LOG_ENABLED) {
        console.log(err);
      }
    }
  };

  const fetchComments = async (sessionId) => {
    const categoryListData = JSON.stringify({
      pubInfo: {
        sessionId: sessionId,
      },
      request: {
        busiParams: {},
        isEncrypt: false,
        transactionId: "897987987989",
      },
    });
    try {
      const response = await Axios.post(
        BASE_URL + GetCategoryLists,
        categoryListData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
          },
        }
      );

      const numAscending = [
        ...response.data.respData.respMsg.categoryList,
      ].sort((a, b) => a.orderNo - b.orderNo);
      localStorage.setItem("cat_ascending_data", JSON.stringify(numAscending));

      const itemData = numAscending?.filter(
        (it) => it.viewType === ABOUT_US_VIEW
      );
      localStorage.setItem("categorydata", JSON.stringify(itemData));

      setComments(numAscending);

      // console.log("Api called finally >>>")
    } catch (err) {
      swal("data : " + err);
      if (DEBUG_LOG_ENABLED) {
        console.log(err);
      }
    }
  };

  return (
    <>
      <HashRouter>
        {/* <BrowserRouter> */}
        <GoogleOAuthProvider clientId="589460195389-c5suckr5gcvflnf08kul6k21lmamlsv1.apps.googleusercontent.com">
          <Template>
            <div>
              {comments?.length > 0 ? (
                <div>
                  <Routing listData={comments} />
                </div>
              ) : (
                <div>
                  <Placeholder />{" "}
                </div>
              )}
            </div>
          </Template>
        </GoogleOAuthProvider>
        {/* </BrowserRouter> */}
        <style>{Custome_scroll}</style>
      </HashRouter>
    </>
  );
}
