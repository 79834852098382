import {
  SESSION_ID,
  CUSTOMER_ID,
  SetOrders,
  BASE_URL,
  ACCESS_TOKEN,
  MYPRODUCT,
  LOGO_DRIVE_ICON,
  GOOGLE_DRIVE_URL,
  USER_INFO,
  DEBUG_LOG_ENABLED,
  ID_REZORPAY,
  STARTRAZORPAYPAYMENT,
  PAYPAL_CLIENT_ID,
  startPhonePePayment,
  PAYPAL_SENDBOX_CLIENT_ID,
  GET_CLIENT_ID,
  BASE_URL_IMG,
  PRIMARY_LIGHT,
  CASH_ON_DELVIERY,
  CART_COUNT,
} from "../Constant";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import { Link } from "react-router-dom";
// import { useDispatch } from "react-redux";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import swal from "sweetalert";

import CheckoutStepper from "../components/CheckoutStepper";
import moment from "moment";
import axios from "axios";
import { useDispatch } from "react-redux";
import { increment } from "../actions";
function OrderReview() {
  // const [sdkReady, setSdkReady] = useState(false);
  // const dispatch = useDispatch();
  const [addressId, setAddressId] = useState();
  const dispatch = useDispatch();
  // const customerId = localStorage.getItem(CUSTOMER_ID);
  const paypalAmount = localStorage.getItem("price");
  const currencyCode = localStorage.getItem("currency");
  const navigate = useNavigate();
  // const [loading, setLoading] = useState(true);
  // const [customerAddress, setCustomerAddress] = useState([]);

  const myData = localStorage.getItem("cartListData");

  const catItemList = JSON.parse(myData);

  const addressData = localStorage.getItem("addressData");
  const addressList = JSON.parse(addressData);

  // const [show, setShow] = useState(false);
  const [paypalsuccess, setPaypalsuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");

  const [orderID, setOrderID] = useState("");
  // const UpiOderId = Math.floor(100000 + Math.random() * 900000);
  // const current_date = new Date();
  const userData = localStorage.getItem(USER_INFO);
  const profiledata = JSON.parse(userData);
  const [twoPayMerchantId, setTwoPayMerchantId] = useState(null);
  const [twoPaySecretKey, setTwoPaySecretKey] = useState(null);
  const [RedirectUrl, setRedirectUrl] = useState("");

  const [Paymentloder, setPaymentloder] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadings, setIsLoadings] = useState(false);
  const cash_payment = 0;
  const online_payment = 1;

  const initializeRazorpay = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      // document.body.appendChild(script);

      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };

  const makePayment = async () => {
    if (DEBUG_LOG_ENABLED) {
      console.log("here...");
    }
    const res = await initializeRazorpay();
    // const order = await createOrder(params);

    const razorpayOrder = JSON.stringify({
      pubInfo: {
        sessionId: localStorage.getItem(SESSION_ID),
      },
      request: {
        busiParams: {
          customerId: localStorage.getItem(CUSTOMER_ID),
          amount: (paypalAmount * 100).toString(),
          currency: currencyCode,
        },
        isEncrypt: false,
        transactionId: "897987987989",
      },
    });

    let response = await axios.post(
      BASE_URL + STARTRAZORPAYPAYMENT,
      razorpayOrder,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      }
    );

    var mOrderId = response?.data?.respData?.respMsg?.orderId;
    if (DEBUG_LOG_ENABLED) {
      console.log(mOrderId);
    }
    if (!res) {
      swal("Razorpay SDK Failed to load");
      return;
    }

    var options = {
      key: localStorage.getItem(ID_REZORPAY), // Enter the Key ID generated from the Dashboard
      name: "Shree Sarv Shakti",
      currency: currencyCode,
      // amount: (paypalAmount * 100).toString(),
      amount: (paypalAmount * 100).toString(),

      order_id: mOrderId,
      description: "Thankyou for your test donation",
      image: GOOGLE_DRIVE_URL + LOGO_DRIVE_ICON,
      handler: function (response) {
        // Validate payment at server - using webhooks is a better idea.
        // swal(response.razorpay_payment_id);
        if (response.razorpay_payment_id !== "") {
          setOrder(response.razorpay_payment_id, online_payment);
        }

        // swal(response.razorpay_order_id);
        // swal(response.razorpay_signature);
      },
      prefill: {
        name: addressList?.name,
        email: profiledata?.emailId,
        contact: addressList?.mobileNo,
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  // creates a paypal order
  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "India",
            amount: {
              currency_code: currencyCode,
              value: paypalAmount,
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
        setOrderID(orderID);
        return orderID;
      });
  };

  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;

      setPaypalsuccess(true);
    });
  };
  //capture likely error
  const onError = (data, actions) => {
    setErrorMessage("An Error occured with your payment ");
  };

  useEffect(() => {
    if (currencyCode === "INR") {
      // getClient();
    }

    if (paypalsuccess) {
      //  swal("Payment successful!!" + orderID);
      setOrder(orderID, online_payment, "");
    } else {
      if (DEBUG_LOG_ENABLED) {
        console.log(1, orderID);
      }
      if (orderID !== "") {
        swal("Payment Fail!!" + ErrorMessage);
      }
      if (DEBUG_LOG_ENABLED) {
        console.log(2, paypalsuccess);
        console.log(3, ErrorMessage);
      }
    }
    const customerId = localStorage.getItem(CUSTOMER_ID);
    if (customerId === undefined || customerId === null) {
      localStorage.setItem(CUSTOMER_ID, "");
      navigate("/login");
    } else {
      setAddressId(addressList.id);
    }
    // localStorage.setItem("UpiOderId" ,UpiOderId);
  }, [paypalsuccess]);

  const setOrder = async (orderID, paymentType, dataUrl) => {
    if (paymentType == 0) {
      setIsLoading(true);
    } else {
      setIsLoadings(true);
    }

    try {
      const setOrderItem = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            // orderId: orderID,
            amount: paypalAmount,
            customerId: localStorage.getItem(CUSTOMER_ID),
            paymentId: orderID,
            addressId: addressId,
            date: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
            paymentType: paymentType,
            currency: currencyCode,
            customerPhone: addressList.mobileNo,
            address: localStorage.getItem("addressData"),
          },
          isEncrypt: false,
          transactionId: "" + moment().valueOf(),
        },
      });

      // console.log(setOrderItem, "payload");
      // const response = await OrderReviewService.create(setOrderItem);
      let response = await axios.post(BASE_URL + SetOrders, setOrderItem, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });
      // console.log(response, " setordesa");

      dispatch(increment(0));
      // localStorage.REMOVEi(CART_C, "");
      localStorage.setItem("price", "");
      localStorage.setItem("currency", "");
      // localStorage.setItem("cartListData", JSON.stringify(cartList));
      localStorage.setItem("clientId", "");
      localStorage.setItem("razorpayKeyId", "");

      localStorage.setItem("orderID", orderID);
      localStorage.removeItem(CART_COUNT);
      setIsLoading(false);
      setIsLoadings(false);
      // if (dataUrl === "") {
      //   navigate("/ordersuccess");
      // } else {
      //   navigate("/Phonpe", { state: { dataUrl } })
      // }
      navigate("/ordersuccess", { replace: true });
    } catch (err) {
      swal("data : " + err);
      if (DEBUG_LOG_ENABLED) {
        console.log(err);
      }
      setIsLoading(false);
      setIsLoadings(false);
    }
  };

  const onPhonpe = async () => {
    try {
      const PhonpeData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            amount: paypalAmount * 100,
            currency: "INR",
            customerId: localStorage.getItem(CUSTOMER_ID),
            deviceOS: "IOS",
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      let response = await axios.post(
        BASE_URL + startPhonePePayment,
        PhonpeData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        }
      );

      setOrder(
        response.data.respData.respMsg.merchantTransactionId,
        online_payment,
        response.data.respData.respMsg.instrumentResponse.redirectInfo.url
      );
    } catch (err) {
      alert("Oops went something wrong ");
      if (DEBUG_LOG_ENABLED) {
        console.log(err);
      }
    }
  };

  //localStorage.getItem(PAYPAL_SENDBOX_CLIENT_ID);
  return (
    <PayPalScriptProvider
      options={{
        "client-id": localStorage.getItem(PAYPAL_CLIENT_ID),
      }}
    >
      <div className="  align-items-center container  py-5 px-xl-5 mx-auto mt-5 maxWidth 900px">
        <div className="row">
          <div className="col-md-12">
            <CheckoutStepper step={2} />
          </div>
        </div>
        <div className="wrapper">
          <div className="col-lg-9">
            <div className="d-flex flex-column ">
              <ScrollToTopOnMount />
              <div className="card p-sm-4 p-2 mb-3 bg-transparent ">
                <p style={{ margin: 0 }}>
                  <span class="h4"> Address </span>
                </p>
                <hr />

                <div
                  className="px-3 py-2 card shadow-sm rounded-3"
                  style={{ backgroundColor: PRIMARY_LIGHT }}
                >
                  <h5>{addressList.name}</h5>
                  <label class="form-check-label" for="flexRadioDefault1">
                    {addressList.addressLine1 + " " + addressList.addressLine2}
                  </label>
                  <p style={{ margin: 0 }} class="form-check-label">
                    {addressList.city +
                      " " +
                      addressList.state +
                      " " +
                      addressList.country +
                      " " +
                      addressList.pincode}
                  </p>
                  <p style={{ margin: 0 }} class="form-check-label">
                    {"Mobile no : " + addressList.mobileNo}
                  </p>
                  <p style={{ margin: 0 }}>
                    {"Email ID : " + addressList.addressLine2}
                  </p>
                </div>
              </div>

              <div class="card mb-5 bg-transparent border-3 ">
                <div class="card-body p-sm-4 p-2">
                  <p className="mb-3 " style={{ margin: 0 }}>
                    <span class="h4"> All Puja </span>
                  </p>
                  <form>
                    {catItemList?.map((item, index) => {
                      return (
                        <div>
                          <section>
                            <div class="px-1 h-100">
                              <div class="row d-flex justify-content-center align-items-center h-100">
                                <div class="col">
                                  <div class="card mb-4 bg-transparent ">
                                    <div class=" p-2 shadow-sm rounded-3 bg-light">
                                      <div class="row ">
                                        <div class="col-md-2 d-flex  justify-content-between ">
                                          <Link
                                            to={"/productdetail/" + item.itemId}
                                            onClick={() =>
                                              localStorage.removeItem(MYPRODUCT)
                                            }
                                          >
                                            <img
                                              className="fluid rounded-3"
                                              width="120px"
                                              height="120px"
                                              src={BASE_URL_IMG + item.iconURL}
                                              alt="iconURL"
                                            />
                                          </Link>
                                        </div>
                                        <div className="col-md-10  px-sm-4 d-flex flex-column justify-content-between  card-title text-left text-dark text-truncate">
                                          <div className="d-flex gap-2 justify-content-between">
                                            <p
                                              className="text-black pt-2 pt-md-0  fw-medium"
                                              style={{
                                                margin: 0,
                                                fontSize: "20px",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              {item.title}
                                            </p>
                                          </div>
                                          <div className="d-flex text-black">
                                            {currencyCode === "USD"
                                              ? "$ " + item.panditCount
                                              : " ₹" + item.price}
                                          </div>
                                          <div
                                            style={{
                                              flexWrap: "wrap",
                                              display: "flex",
                                              gap: "4px",
                                            }}
                                          >
                                            <label
                                              for="birthdaytime"
                                              className="pe-sm-3 pe-2"
                                            >
                                              Pujan Date{" "}
                                            </label>
                                            <label>
                                              {moment(
                                                item?.date,
                                                "YYYY-MM-DD HH:mm"
                                              ).format("DD-MMM-YYYY hh:mm a")}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      );
                    })}

                    <div
                      class="clearfix rounded-3 pt-1"
                      style={{ backgroundColor: PRIMARY_LIGHT, margin: 0 }}
                    >
                      <div class="float-start">
                        <p
                          style={{ margin: 0 }}
                          class=" ms-5 fs-4 d-flex align-items-center"
                        >
                          Sub Total
                        </p>
                      </div>
                      <div class="float-end">
                        <p
                          style={{ margin: 0 }}
                          class="mb-0 me-5 pt-1 fs-4 d-flex align-items-center"
                        >
                          <span class="h4">
                            {currencyCode === "USD" ? " $" : " ₹"}
                          </span>
                          <span class="h4">{paypalAmount}</span>
                        </p>
                      </div>
                    </div>
                  </form>
                  <div class="d-flex justify-content-center pt-3">
                    {/* <PayPalButtons
                      style={{ layout: "vertical" }}
                      createOrder={createOrder}
                      onApprove={onApprove}
                    /> */}
                  </div>
                  <div class=" content-center pt-3 w-100">
                    <span class="h4">
                      {currencyCode === "USD" ? (
                        <>
                          <PayPalButtons
                            style={{ layout: "vertical" }}
                            createOrder={createOrder}
                            onApprove={onApprove}
                          />

                          <hr class="hr hr-blurry p-3" data-content="OR" />
                        </>
                      ) : (
                        <div className="d-flex gap-4">
                          <button
                            style={{ layout: "vertical" }}
                            class="btn btn-primary btn-md text-white w-100"
                            onClick={() =>
                              setOrder(CASH_ON_DELVIERY, cash_payment)
                            }
                          >
                            Pay Later {/* Razor Pay */}{" "}
                            {isLoading && (
                              <div
                                className="spinner-border text-white "
                                style={{
                                  height: 14,
                                  width: 14,
                                  fontSize: "10px",
                                }}
                                role="status"
                              ></div>
                            )}
                          </button>
                          <button
                            style={{ layout: "vertical" }}
                            class="btn btn-primary btn-md text-white w-100"
                            onClick={makePayment}
                          >
                            Continue To Pay {/* Razor Pay */}{" "}
                            {isLoadings && (
                              <div
                                className="spinner-border text-white "
                                style={{
                                  height: 14,
                                  width: 14,
                                  fontSize: "10px",
                                }}
                                role="status"
                              ></div>
                            )}
                          </button>

                          {/* <button
                            class="btn btn-primary btn-lg w-100 mt-3"
                            onClick={onPhonpe}
                          >
                            Continue to pay
                          </button> */}
                        </div>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PayPalScriptProvider>
  );
}

export default OrderReview;
